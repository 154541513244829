import { styled } from "styled-components";
// import In
// the text field: input
export const TextField = styled.input<{ line?: boolean }>`
  font-size: 16px;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: ${(props) => (props.line ? "1px solid #268ecb" : "none")};

  font-family: Poppins, sans-serif;
  padding: 8px 0px;
  border-radius: 0px;
  &:focus {
    outline: none;
  }
`;

// A button
export const Button = styled.button<{
  line?: boolean;
  primary?: boolean;
  disabled?: boolean;
}>`
  font-size: 16px;
  width: 90%;
  background-color: ${(props) => (props.primary ? "var(--primary)" : "none")};
  border-radius: 5px;
  color: ${(props) => (props.primary ? "#fff" : "#000")};
  text-transform: uppercase;
  outline: none;
  border: none;
  border-bottom: ${(props) => (props.line ? "1px solid gray" : "none")};
  font-weight: 600;
  font-family: Poppins, sans-serif;
  padding: 10px 0px;
  cursor: pointer;
`;

// Outlined Button
export const ButtonOutlined = styled.button<{
  width?: string | number;
  primary?: boolean;
}>`
  font-size: 16px;
  width: ${(props) => (props.width ? props.width : "90%")};
  background-color: #fff;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "var(--primary)" : "#000")};
  outline: none;
  border: 0.5px solid
    ${(props) => (props.primary ? "var(--primary)" : "#00000044")};
  font-weight: 600;

  fontfamily: "Source Sans Pro";
  padding: 5px 10px;
  cursor: pointer;
`;
