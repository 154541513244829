import api from ".";
import {
  UtilisateurAccountResponse,
  WhiteUrlResponse,
} from "../interface/Accounts";

const create = async (data: {
  idOrganisation: string;
  nom: string;
  email: string;
  api: string;
  mdp: string;
  cleApi: string;
  cleSecret: string;
}) => {
  const res = await api.post("/compte", data);
  const reponse = JSON.parse(res.data);
  return reponse;
};

const deleteAccount = async (idCompte: string) => {
  const res = await api.delete("/compte/" + idCompte);
  // const data = JSON.parse(res.data);
  return;
};

const changeStatus = async (data: { idCompte: string; actif: boolean }) => {
  const res = await api.patch("/compte/status/" + data.idCompte, {
    actif: data.actif,
  });
  const response = JSON.parse(res.data);
  return response;
};

const updateAccount = async (data: {
  idCompte: string;
  idOrganisation: string;
  nom: string;
  email: string;
  api: string;
  mdp: string;
  cleApi: string;
  cleSecret: string;
}) => {
  const res = await api.patch("/compte/" + data.idCompte, {
    idOrganisation: data.idOrganisation,
    nom: data.nom,
    email: data.email,
    api: data.api,
    mdp: data.mdp,
    cleApi: data.cleApi,
    cleSecret: data.cleSecret,
  });
  const response = JSON.parse(res.data);
  return response;
};

const getAllAccount = async () => {
  const res = await api.get("/compte/by-me");
  const response: UtilisateurAccountResponse[] = JSON.parse(res.data);
  return response;
};

const getOneAccount = async ({ queryKey }: { queryKey: AccountQueryKey }) => {
  const [_, idCompte] = queryKey;
  const res = await api.get("/compte/" + idCompte);
  const response: UtilisateurAccountResponse = JSON.parse(res.data);
  return response;
};

const getWhiteserver = async () => {
  const res = await api.get("/server/white");
  const response: WhiteUrlResponse[] = JSON.parse(res.data);
  return response;
};

type AccountQueryKey = ["account", string];
export {
  create,
  updateAccount,
  changeStatus,
  deleteAccount,
  getAllAccount,
  getOneAccount,
  getWhiteserver,
};
