import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/Header";
import ModalAddConfiguration from "../../components/ModalAddConfiguration";
import TableConnector from "./TableConnector";
import { getOneConnector } from "../../api/connector";
import TableConnectorTransaction from "./TableConnectorTransaction";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";

function ConfigConnector() {
  const [showAddConfig, setShowAddConfig] = useState(false);
  const { id } = useParams() || "";
  const connector = useQuery({
    queryKey: ["connector", id || ""],
    queryFn: getOneConnector,
  });

  return (
    <>
      {showAddConfig && (
        <ModalAddConfiguration
          visible={showAddConfig}
          change={setShowAddConfig}
        />
      )}
      <Header isConfig={true} />
      <Spin spinning={connector.isLoading}>
        <Container>
          <div>
            <Head>
              <TitleSub>Connecteur à configurer</TitleSub>
              <Info>{connector.data?.nom}</Info>
            </Head>
            <Title style={{ marginTop: 0 }}>FORMULAIRE DAXIUM </Title>
            <TableConnector />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "flex-end",
                margin: "0",
              }}
            >
              <Button onClick={() => setShowAddConfig(true)}>
                Ajouter une configuration
              </Button>
            </div>
          </div>
          <div>
            <TableConnectorTransaction />
          </div>
        </Container>
      </Spin>
    </>
  );
}

const Container = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 6px;
  padding: 10px;
  height: 90vh;

  display: grid;
  grid-template-rows: 50% 50%;
`;
const Button = styled.button`
  width: 250px;
  height: 40px;
  align-items: center;
  background: #4e8ccb;
  border-radius: 5px;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  padding: 0 2px;
  color: white;
  border: 1px solid white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all in ease;
  fontweight: bold;
  fontsize: 1.4em;
  &:hover {
    background: #4e8ddf;
    cursor: pointer;
  }
`;

const Title = styled.p`
  text-align: left;
  font-size: 1rem;
`;
const TitleSub = styled.p`
  color: #64727e;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5625rem;
  margin-right: 3px;
  text-align: left;
`;
const Info = styled.span`
  background: #a4b9cf4d;
  border: 0.5px solid #a4b9cf1a;
  border-radius: 5px;
  box-shadow: 0 1px 3px #0003;
  height: 100%;
  text-align: center;
  margin-left: 15px;
  width: auto;
  padding-left: 2px;
  padding-right: 2px;
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`;

export default ConfigConnector;
