import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProtectedRouteProps } from "../models/components-props.model";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element: Component,
}) => {
  const navigate = useNavigate();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (!token || !user) {
      navigate("/auth");
    } else {
      setShouldRender(true);
    }
  }, [navigate]);

  return shouldRender ? Component : null;
};

export default ProtectedRoute;
