import api from ".";
import { FileInfo, AccountDrive, Site } from "../interface/drive";

const getAllDriveCategorie = async () => {
  const res = await api.get("/categorie-drive");
  const response: CategorieDrive[] = JSON.parse(res.data);
  return response;
};

const createDrive = async (data: {
  idCategorieDrive: string;
  idProprietaire: string;
  idOrganisation: string;
  nom: string;
  tockenAccess: string;
  tockenRafraichissement: string;
  dateExpirationTocken: number;
}) => {
  const res = await api.post("/drive", data);
  const reponse = JSON.parse(res.data);
  return reponse;
};

const getAllDrive = async () => {
  const res = await api.get("/drive");
  const response: DriveObject[] = JSON.parse(res.data);

  return response;
};

const getCodeUrl = async () => {
  const res = await api.get("/drive/code-microsoft");
  const response: { url: string } = JSON.parse(res.data);
  return response;
};

const updateDrive = async (data: {
  idAccountDrive: string;
  idCategorieDrive: string;
  idProprietaire: string;
  nom: string;
  idOrganisation: string;
  tockenAccess: string;
  tockenRafraichissement: string;
  dateExpirationTocken: number;
  root: string;
  site: string;
}) => {
  const res = await api.patch("/drive/" + data.idAccountDrive, {
    idCategorieDrive: data.idCategorieDrive,
    idProprietaire: data.idProprietaire,
    nom: data.nom,
    idOrganisation: data.idOrganisation,
    tockenAccess: data.tockenAccess,
    tockenRafraichissement: data.tockenRafraichissement,
    dateExpirationTocken: data.dateExpirationTocken,
    root: data.root,
    site: data.site,
  });
  const response: DriveObject = JSON.parse(res.data);
  return response;
};

const getOneDrive = async ({ queryKey }: { queryKey: driveQueryKey }) => {
  const [_, idDrive] = queryKey;
  const res = await api.get("/drive/" + idDrive);
  const response: AccountDrive = JSON.parse(res.data);
  return response;
};

const deleteDrive = async (data: { idDrive: string }) => {
  const res = await api.delete("/drive/" + data.idDrive);
};

const getFilesDrive = async ({
  queryKey,
}: {
  queryKey: FilesDrivesQueryKey;
}) => {
  const [_, idDrive] = queryKey;
  const res = await api.get("/drive/get-files-by-drive/" + idDrive);
  const response = JSON.parse(res.data);
  // console.log(response);
  return response;
};

const getFileInfoDrive = async ({
  queryKey,
}: {
  queryKey: FilesDriveInfoQueryKey;
}) => {
  const [_, data] = queryKey;

  const res = await api.get(
    `/drive/get-file-info?idDrive=${data.idDrive}&idFile=${data.idFile}&site=${data.siteInDrive}`
  );
  const response: FileInfo = JSON.parse(res.data);
  return response;
};

const getFolderbyDrive = async ({ queryKey }: { queryKey: FolderDrive }) => {
  const [_, idDrive] = queryKey;

  const res = await api.get(`/drive/get-files-by-drive/${idDrive}`);
  const response: any = JSON.parse(res.data);
  return response;
};

const getSharePointSites = async ({
  queryKey,
}: {
  queryKey: SitesDriveInfoQueryKey;
}) => {
  const [_, data] = queryKey;

  const res = await api.get(`/drive/sharepoint-sites/${data.idDrive}`);
  const response: Site[] = JSON.parse(res.data);
  return response;
};

const getDriveNode = async ({ queryKey }: { queryKey: DriveNode }) => {
  const [_, data] = queryKey;
  let url = `/drive/drive-node/?drive=${data.drive}`;
  if (data.site) url += `&site=${data.site}`;
  if (data.id) url += `&id=${data.id}`;
  const res = await api.get(url);
  const response: any = JSON.parse(res.data);
  return response;
};
const getDriveNodeF = async ({
  queryKey,
}: {
  queryKey: AnotherDriveNodeKey;
}) => {
  const [_, data] = queryKey;
  let url = `/drive/drive-node/?drive=${data.drive}`;
  if (data.site) url += `&site=${data.site}`;
  if (data.id) url += `&id=${data.id}`;
  const res = await api.get(url);
  const response: any = JSON.parse(res.data);
  return response;
};

type driveQueryKey = ["driveInfo", string];
type FolderDrive = ["FolderDrive", string];
type FilesDrivesQueryKey = ["drivesFiles", string];
type FilesDriveInfoQueryKey = [
  "FileInfo",
  { idFile: string; idDrive: string; siteInDrive: string }
];
type SitesDriveInfoQueryKey = ["Sites", { idDrive: string }];
type DriveNode = [
  "DriveNodeFile",
  { id: string | null; site: string | null; drive: string }
];
type AnotherDriveNodeKey = [
  "AnotherDriveNode",
  { id: string | null; site: string | null; drive: string }
];

export {
  getAllDriveCategorie,
  getFilesDrive,
  getAllDrive,
  getOneDrive,
  updateDrive,
  createDrive,
  deleteDrive,
  getFileInfoDrive,
  getCodeUrl,
  getFolderbyDrive,
  getDriveNode,
  getDriveNodeF,
  getSharePointSites,
};

export type { DriveObject };
interface CategorieDrive {
  id: string;
  nom: string;
  code: string;
  image: string;
  createdDate: string;
  updatedDate: string;
}
interface DriveObject {
  id: string;
  idCategorieDrive: string;
  idProprietaire: string;
  idOrganisation: string | null;
  nom: string;
  tockenAccess: string;
  root: string;
  site: string;
  tockenRafraichissement: string | null;
  dateExpirationTocken: string | null;
  createdDate: string;
  updatedDate: string;
}
