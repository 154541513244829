import React, { useState, useEffect } from "react";
import { Input, Modal, Button, Checkbox, Select } from "antd";
import styled from "styled-components";

interface propsCustomSelect {
  fields: any[];
  onChange: Function;
  value: string;
  placeholder: string;
  required: boolean;
  isCallback?: boolean;
  isConcat?: boolean;
  type?: string;
}

const App: React.FC<propsCustomSelect> = ({
  fields,
  onChange,
  value,
  placeholder,
  required,
  isCallback,
  isConcat = false,
  type = "1",
}) => {
  const types: string[] = [];
  for (let index = 0; index < fields.length; index++) {
    const element = fields[index].type;
    if (!types.includes(element) && fields[index].label !== undefined) {
      if (
        isConcat &&
        [
          "image",
          "user",
          "signature",
          "file",
          "list",
          "relation",
          "location",
        ].includes(element)
      ) {
        continue;
      }
      types.push(element);
    }
  }

  const [visible, setVisible] = useState(false);
  const [dataTypes, setDataTypes] = useState(["tous"]);
  const [fieldsDaxium, setFieldsDaxuim] = useState<field[]>([]);
  const [all, setAll] = useState(false);

  useEffect(() => {
    if (dataTypes.includes("tous")) {
      let f: field[] = fields.map((t) => {
        return { label: t.label, type: t.type, status: false, name: t.name };
      });
      if (isConcat) {
        f = f.filter(
          (item) =>
            ![
              "image",
              "user",
              "signature",
              "file",
              "list",
              "relation",
              "location",
            ].includes(item.type)
        );
      }
      setFieldsDaxuim(f);
    } else {
      let f: field[] = fields
        .filter((el) => dataTypes?.includes(el.type))
        .map((t) => {
          return { label: t.label, type: t.type, status: false, name: t.name };
        });
      if (isConcat) {
        f = f.filter(
          (item) =>
            ![
              "image",
              "user",
              "signature",
              "file",
              "list",
              "relation",
              "location",
            ].includes(item.type)
        );
      }
      setFieldsDaxuim(f);
    }
  }, [dataTypes]);

  useEffect(() => {
    let filter = fields.map((t: field) => {
      return { label: t.label, type: t.type, status: false, name: t.name };
    });

    if (isConcat) {
      filter = filter.filter(
        (item) =>
          ![
            "image",
            "user",
            "signature",
            "file",
            "list",
            "relation",
            "location",
          ].includes(item.type)
      );
    }
    setFieldsDaxuim(filter);
  }, [fields]);
  const handleSaveInput = () => {
    const text =
      (isConcat ? value.slice(0, -1) : "") +
      (isCallback ? "" : "#") +
      fieldsDaxium
        .filter((f) => f.status)
        .map((t) => t.name)
        .toString()
        .replaceAll(",", isConcat ? " #" : "|#");
    setFieldsDaxuim(
      fieldsDaxium.map((item) => {
        item.status = false;
        return item;
      })
    );
    setDataTypes(["tous"]);
    onChange(text);
  };
  return (
    <div>
      {type === "1" && (
        <Input
          type="text"
          placeholder={placeholder}
          required={required}
          onChange={(event) => {
            const val = event.target.value;
            if (
              val.slice(-1) === "#" &&
              val.length > value.length &&
              fields.length > 0
            ) {
              setVisible(true);
            }

            onChange(event.target.value);
          }}
          value={value}
          size="large"
          style={{ height: 40, width: 400, marginTop: 0 }}
        />
      )}
      {type === "8" && (
        <Input.TextArea
          placeholder={placeholder}
          required={required}
          onChange={(event) => {
            const val = event.target.value;
            if (
              val.slice(-1) === "#" &&
              val.length > value.length &&
              fields.length > 0
            ) {
              setVisible(true);
            }

            onChange(event.target.value);
          }}
          value={value}
          size="large"
          style={{ width: 400, marginTop: 0 }}
        />
      )}
      {visible && (
        <Modal
          width={500}
          centered
          style={{
            padding: 0,
            margin: "auto",
          }}
          title="CHAMPS FORMULAIRES"
          visible={visible}
          onCancel={() => {
            setVisible(false);
            handleSaveInput();
          }}
          footer={
            <Button
              key="back"
              size={"large"}
              onClick={() => {
                handleSaveInput();
                setFieldsDaxuim([]);
                setVisible(false);
              }}
              style={{
                background: "#4e8ccb",
                color: "white",
                fontSize: "1rem",
                paddingLeft: "13px",
                paddingRight: "13px",
                textAlign: "center",
                width: "auto",
              }}
            >
              Fermer
            </Button>
          }
        >
          <div
            style={{
              display: "grid",
              gridTemplateRows: "repeat(auto-fill,50%)",
              gap: 10,
              padding: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p>Filtrer par : </p>
              <Select
                showSearch={true}
                optionFilterProp="label"
                style={{ width: 350 }}
                mode="multiple"
                optionLabelProp="label"
                defaultValue={["tous"]}
                value={dataTypes}
                onChange={(val: string[]) => {
                  if (val[val.length - 1] === "tous") {
                    setDataTypes(["tous"]);
                  } else {
                    setDataTypes(val.filter((t) => t !== "tous"));
                  }
                }}
                options={[
                  { label: "tous", value: "tous" },
                  ...types?.map((item) => {
                    return { label: item, value: item };
                  }),
                ]}
              />
            </div>
            {!isCallback && (
              <Checkbox
                indeterminate={
                  fieldsDaxium?.filter((f) => f?.status).length > 0 && !all
                }
                checked={
                  all &&
                  fieldsDaxium?.filter((f) => f?.status).length ===
                    fieldsDaxium?.length
                }
                onChange={() => {
                  const f = fieldsDaxium?.map((t: any) => {
                    t.status = !all;
                    return t;
                  });
                  setFieldsDaxuim(f);
                  setAll(!all);
                }}
              >
                Tous sélectionnés
              </Checkbox>
            )}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill,50%)",
            }}
          >
            {fieldsDaxium &&
              fieldsDaxium.map((f: field) => {
                return (
                  f.label !== undefined && (
                    <Checkbox
                      checked={f.status}
                      onChange={() => {
                        const updateStatus = fieldsDaxium?.map((t) => {
                          if (isCallback) t.status = false;
                          if (t.label === f.label) {
                            t.status = !t.status;
                          }
                          return t;
                        });
                        setFieldsDaxuim(updateStatus);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                        margin: 0,
                        cursor: "pointer",
                        color: "#4e8ccb",
                      }}
                    >
                      {f.label}
                    </Checkbox>
                  )
                );
              })}
          </div>
        </Modal>
      )}
    </div>
  );
};
const Element = styled.p`
  // border-top: 1px solid #0000001f;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  color: #4e8ccb;
  // heigth: 65px;
  &:hover {
    background: rgba(78, 140, 203, 0.2);
  }
`;
export default App;
interface field {
  type: string;
  label: string;
  name: string;
  status: boolean;
}
