import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { refresh } from "../../api/auth";
import {
  CardForm,
  Center,
  CenterScreen,
  Col,
} from "../../components/styled/Styled.Container";
import logo from "../../assets/daxium-logo.png";
import { Logo } from "../../components/styled/Styled.Asset";
import { Title2, Title4 } from "../../components/styled/Styled.Typography";
import { Button } from "../../components/styled/Styled.Form";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const AcceptInvitation = () => {
  const location = useLocation();
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const extractTokenFromURL = () => {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get("token");
      if (token) {
        localStorage.setItem("token", token);
        setToken(token);
      }
    };
    extractTokenFromURL();
  }, [location.search]);

  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: refresh,
    enabled: Boolean(token),
  });

  if (userInfo.isSuccess) {
    localStorage.setItem("user", JSON.stringify(userInfo.data));
  }

  if (userInfo.isError) {
    messageApi.open({
      type: "error",
      content:
        "Token invalide, vous allez être redirigé vers l'écran de connexion",
      duration: 2,
    });
  }

  const submit = () => {
    if (userInfo.isSuccess) {
      navigate("/");
    } else {
      navigate("/auth");
    }
  };

  return (
    <CenterScreen>
      {contextHolder}
      <Col>
        <Logo srcSet="" src={logo} alt="logo-daxium" />
        <CardForm
          loading={userInfo.isLoading}
          style={{
            minWidth: 410,
            maxWidth: 410,
            minHeight: 200,
          }}
        >
          <Center column>
            <Col>
              {" "}
              {/* Le titre du formulaire */}
              <Title2>
                Bienvenue sur
                <Title2 capitalize primary ml>
                  Daxium-connect
                </Title2>
              </Title2>
              <Title4>Vous avez été rataché à une organisation</Title4>
            </Col>

            {/* LES ETAPES DE CONNEXION AU COMPTE */}

            <Button
              style={{
                width: "85%",
                backgroundColor: "#4E8CCB",
                color: "white",
                textTransform: "uppercase",
                fontWeight: "700",
                fontFamily: "Source Sans Pro",
              }}
              disabled={userInfo.isLoading}
              onClick={submit}
            >
              {userInfo.isSuccess
                ? "Accéder au Dashboard"
                : "Token invalide, Se connecter"}
            </Button>
          </Center>
        </CardForm>
      </Col>
    </CenterScreen>
  );
};

export default AcceptInvitation;
