import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Button, Space, Select, message } from "antd";
import styled from "styled-components";
import "react-multi-email/dist/style.css";

import { ReactMultiEmail } from "react-multi-email";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invited } from "../api/organisation";

const ModalInvitedUser: React.FC<modalProps> = ({
  visible,
  change,
  organisation,
}) => {
  useEffect(() => {
    setIdOrganisation(organisation?.value);
  }, [organisation]);
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: invited,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["Organisations"] });
      messageApi.open({
        type: "success",
        content: "les invitations ont été envoyés avec succès",
        duration: 2,
      });
      handleOk();
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: "Une erreur est surevnue lors des envoies des invitations",
        duration: 2,
      });
      handleCancel();
    },
  });
  const [idOrganisation, setIdOrganisation] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleOk = () => {
    change(false);
    clearData();
  };

  const submit = () => {
    setBtnLoading(true);
    try {
      if (!idOrganisation)
        throw new Error("Vous devez sélectionnés une organisation");

      if (emails?.length === 0)
        throw new Error("Vous devez renseignez minimum une adresse mail");
    } catch (e: any) {
      messageApi.open({
        type: "warning",
        content: e.message ? e.message : "",
        duration: 2,
      });
      setBtnLoading(false);
      return;
    }

    if (idOrganisation && emails) {
      mutate.mutate({ idOrganisation, emails });
    }
  };

  const handleCancel = () => {
    change(false);
    clearData();
  };
  // Cleaning the form
  const clearData = () => {
    setIdOrganisation("");
    setEmails([]);
    setBtnLoading(false);
  };

  return (
    <Modal
      style={{ top: 20 }}
      title=""
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={560}
      centered
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            key="back"
            size={"large"}
            onClick={handleCancel}
            style={{
              fontSize: "1rem",
              padding: "5px",
              textAlign: "center",
              width: "120px",
            }}
          >
            ANNULER
          </Button>
          <Button
            key="submit"
            size={"large"}
            onClick={submit}
            loading={btnLoading}
            style={{
              background: "#4e8ccb",
              color: "white",
              fontSize: "1rem",
              paddingLeft: "13px",
              paddingRight: "13px",
              textAlign: "center",
              width: "auto",
            }}
          >
            INVITER
          </Button>
        </div>,
      ]}
    >
      {contextHolder}
      <div style={{ padding: "10px" }}>
        <Content>Inviter des utilisateurs</Content>
        <Container>
          <ContainerInput direction="horizontal">
            <Title>
              Organisation <span style={{ color: "red" }}>*</span> :{" "}
            </Title>
            <Select
              showSearch={true}
              optionFilterProp="label"
              disabled={true}
              style={{ width: 300 }}
              size="large"
              value={idOrganisation}
              onChange={setIdOrganisation}
              options={[organisation]}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Emails <span style={{ color: "red" }}>*</span> :{" "}
            </Title>
            <ReactMultiEmail
              style={{ width: 300 }}
              placeholder="Entrez les addresse mails"
              emails={emails}
              onChange={(email: string[]) => {
                setEmails([...email]);
              }}
              getLabel={(email, index: number, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span
                      data-tag-handle
                      onClick={() => removeEmail(index)}
                      style={{
                        color: "#444",
                        fontWeight: "bold",
                        fontSize: 13,
                      }}
                    >
                      x
                    </span>
                  </div>
                );
              }}
            />
          </ContainerInput>
        </Container>
      </div>
    </Modal>
  );
};

export default ModalInvitedUser;
const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-size: 1.02rem;
  width: 150px;
  text-align: end;
  align-items: left;
  font-weight: 650;
`;

const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  text-tranform: uppercase;
  border-radius: 5px;
  padding: 3px;
  text-transform: uppercase;
`;
interface modalProps {
  visible: boolean;
  organisation: selected;
  change: Function;
}

interface selected {
  label: string;
  value: string;
}
