import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ConnectorState, Connecteur } from "../models/connector.model";

const initialState: ConnectorState = {
  isLoading: false,
  connectors: [],
};

export const connectorSlice = createSlice({
  name: "connector",
  initialState,
  reducers: {
    setConnectors: (state, action: PayloadAction<Connecteur[]>) => {
      state.connectors = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setConnectors, setIsLoading } = connectorSlice.actions;

export const selectConnector = (state: RootState) => state.connector;

export default connectorSlice.reducer;
