import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Button, Input, Space, Select, message } from "antd";
import styled from "styled-components";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { createDrive, getAllDriveCategorie } from "../api/drive";
import { getAllOrganisation } from "../api/organisation";
import { refresh } from "../api/auth";
import { getCodeUrl } from "../api/drive";
import ModalFileDrive from "./ModalFolderDrive";
import { getFilesDrive } from "../api/drive";

const ModalDrive: React.FC<modalProps> = ({ visible, change }) => {
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();
  const [compte, setCompte] = useState<accountInfo | null>(null);
  const [idDrive, setIdDrive] = useState<string>("");

  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: refresh,
  });

  // const getStructureFolder = useQuery({
  //   queryKey: ["drivesFiles", idDrive || ""],
  //   queryFn: getFilesDrive,
  //   enabled: Boolean(idDrive),
  // });

  const urlInfo = useQuery({
    queryKey: ["urlAccount"],
    queryFn: getCodeUrl,
  });

  const addAccountMutation = useMutation({
    mutationFn: createDrive,
    onSuccess: (data) => {
      // console.log(data);
      queryClient.invalidateQueries({ queryKey: ["drivesAccount"] });
      messageApi.open({
        type: "success",
        content: "Le compte a été supprimé acec succès",
        duration: 2,
      });
      handleCancel();
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue lors de la suppréssion du",
        duration: 2,
      });
    },
  });

  const CategorieDrive = useQuery({
    queryKey: ["CategorieDrive"],
    queryFn: getAllDriveCategorie,
  });

  const Organisations = useQuery({
    queryKey: ["Organisations"],
    queryFn: getAllOrganisation,
  });

  const getCode = (url: string) => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const windowWidth = 500;
    const windowHeight = 600;

    const left = (screenWidth - windowWidth) / 2;
    const top = (screenHeight - windowHeight) / 2;

    const authWindow = window.open(
      url,
      "Authentication",
      `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
    );

    const checkForAuthorizationCode = (event: any) => {
      if (authWindow) {
        if (event.source == authWindow) {
          if (event.data) {
            const data = JSON.parse(event.data);
            setCompte(data);
            // console.log("data", data);
          }
          try {
            window.removeEventListener("message", checkForAuthorizationCode);
            authWindow.close();
          } catch (error) {
            console.error("Error checking for authorization code:", error);
          }
        }
      }
    };

    // Écouter les changements d'URL dans la fenêtre d'authentification
    window.addEventListener("message", checkForAuthorizationCode);
  };

  const formattedDrives: { label: string; value: string; key: string }[] = [];
  const formattedOrganisation: { label: string; value: string; key: string }[] =
    [{ label: "Aucune", value: "", key: "aucune" }];

  if (CategorieDrive.isSuccess && CategorieDrive.data) {
    for (let i = 0; i < CategorieDrive.data.length; i++) {
      const element = CategorieDrive.data[i];
      formattedDrives.push({
        label: element.nom,
        value: element.code,
        key: element.id,
      });
    }
  }

  if (Organisations.isSuccess && Organisations.data) {
    for (let i = 0; i < Organisations.data.length; i++) {
      const element = Organisations.data[i];
      formattedOrganisation.push({
        label: element.nom,
        value: element.id,
        key: element.id,
      });
    }
  }
  useEffect(() => {
    if (formattedDrives.length > 0) {
      setDrive(formattedDrives[0].value);
    }
  }, [CategorieDrive.isSuccess]);

  const [titre, setTitre] = useState<string>("");
  const [drive, setDrive] = useState<string | null>(null);
  const [organisation, setOrganisation] = useState<string>("");

  const submit = () => {
    try {
      if (!titre.trim()) throw new Error("Vous n'avez pas entré de Titre");
    } catch (e: any) {
      messageApi.open({
        type: "warning",
        content: e.message ? e.message : "",
        duration: 2,
      });
      return;
    }
    const url = urlInfo.data?.url || "";
    if (url && !compte) {
      getCode(url);
    }
    // console.log(compte);
    if (titre && drive && compte && CategorieDrive.data && compte) {
      // console.log(compte);
      const idCategorieDrive = CategorieDrive.data.find(
        (cat) => cat.code === drive
      )?.id;
      const idProprietaire = userInfo.data?.id;
      const tockenRafraichissement = compte.data.refreshToken;
      const tockenAccess = compte.data.accessToken;
      const dateExpirationTocken =
        compte.data.createdAt + compte.data.expiresIn * 1000;

      if (
        idCategorieDrive &&
        idProprietaire &&
        tockenRafraichissement &&
        tockenAccess &&
        dateExpirationTocken
      ) {
        addAccountMutation.mutate({
          idCategorieDrive,
          idOrganisation: organisation,
          idProprietaire,
          nom: titre,
          tockenRafraichissement,
          tockenAccess,
          dateExpirationTocken,
        });
      }
    }
  };
  const handleOk = () => {
    clearData();
    change(false);
  };

  const handleCancel = () => {
    clearData();
    change(false);
  };
  // Cleaning the form
  const clearData = () => {
    setTitre("");
    setDrive(null);
    setOrganisation("aucune");
    setCompte(null);
  };
  return (
    <Modal
      style={{ top: 20 }}
      title=""
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={560}
      centered
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            key="back"
            size={"large"}
            onClick={handleCancel}
            style={{
              fontSize: "1rem",
              padding: "5px",
              textAlign: "center",
              width: "120px",
            }}
          >
            ANNULER
          </Button>

          <Button
            key="submit"
            size={"large"}
            style={{
              background: "#4e8ccb",
              color: "white",
              fontSize: "1rem",
              paddingLeft: "13px",
              paddingRight: "13px",
              textAlign: "center",
              width: "auto",
            }}
            // disabled={true}
            onClick={submit}
            loading={addAccountMutation.isPending || urlInfo.isLoading}
          >
            {compte ? "AJOUTER LE DRIVE" : "SE CONNECTER"}
          </Button>
        </div>,
      ]}
    >
      <div style={{ padding: "10px" }}>
        {contextHolder}
        <Content>AJOUT DU DRIVE</Content>
        <Container>
          <ContainerInput direction="horizontal">
            <Title>
              Titre <span style={{ color: "red" }}>*</span> :
            </Title>
            <Input
              size="large"
              style={{ height: 40, width: 300 }}
              value={titre}
              onChange={(e) => {
                setTitre(e.target.value);
              }}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Fournisseur <span style={{ color: "red" }}>*</span> :{" "}
            </Title>
            <Select
              showSearch={true}
              optionFilterProp="label"
              style={{ width: 300 }}
              size="large"
              value={drive}
              onChange={setDrive}
              options={formattedDrives}
              loading={CategorieDrive.isLoading}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>Organisation : </Title>
            <Select
              showSearch={true}
              optionFilterProp="label"
              style={{ width: 300 }}
              size="large"
              value={organisation}
              onChange={setOrganisation}
              options={formattedOrganisation}
              loading={Organisations.isLoading}
            />
          </ContainerInput>
        </Container>
      </div>
      {contextHolder}
    </Modal>
  );
};

export default ModalDrive;
const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-size: 1.02rem;
  width: 150px;
  text-align: end;
  align-items: left;
  font-weight: 650;
`;

const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  text-tranform: uppercase;
  border-radius: 5px;
  padding: 3px;
`;
interface modalProps {
  visible: boolean;
  change: Function;
}
interface selected {
  label: string;
  value: number;
}
interface accountInfo {
  status: "ok|ko";
  data: {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    createdAt: number;
    scope: string;
  };
}
