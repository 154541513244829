import api from ".";

const getConfigMappingFile = async (data: {
  urlApi: string;
  data: string;
  type: string;
}) => {
  const res = await api.post(data.urlApi, {
    data: data.data,
    type: data.type,
  });
  const response = JSON.parse(res.data);
  return response;
};

const getConfigMappingUrl = async (data: {
  urlApi: string;
  url: string;
  type: string;
}) => {
  const res = await api.post(data.urlApi, {
    url: data.url,
    type: data.type,
  });
  const response = JSON.parse(res.data);
  return response;
};

export { getConfigMappingFile, getConfigMappingUrl };
