import React, { useState } from "react";
import CustomInput from "./CustomInput";
import { ConnectorConfigInputObjProps } from "../models/components-props.model";
import styled from "styled-components";
import { Checkbox, Space, Switch, Tooltip } from "antd";
import SelectCustom from "./SelectCustom";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { InfoCircleOutlined, RollbackOutlined } from "@ant-design/icons";

const InputFieldObj: React.FC<ConnectorConfigInputObjProps> = ({
  id = "",
  index,
  required,
  placeholder,
  value,
  setValue,
  type,
  aide,
  fields,
  isCallback = false,
  isConcat = false,
  enfants = [],
  libelle,
}) => {
  const [isFile, setIsFile] = useState(true);

  return (
    <ContainerInput
      style={{
        flexDirection: type === "5" ? "row" : "column",
        alignItems: type === "5" ? "end" : "",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {type !== "6" && (
        <Title
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // border: "1px solid black",
            width: 400,
          }}
        >
          <p>
            {libelle}
            {isCallback && (
              <span>
                <RollbackOutlined />
              </span>
            )}{" "}
            {required && <span style={{ color: "red" }}>*</span>}
          </p>
          {aide && (
            <Tooltip
              title={() => <p style={{ color: "#444" }}> {aide} </p>}
              color={"white"}
            >
              <InfoCircleOutlined
                style={{ color: "#444", cursor: "pointer" }}
              />
            </Tooltip>
          )}
        </Title>
      )}
      {type === "1" && value[index] != null && (
        <SelectCustom
          fields={fields}
          onChange={(val: string) => {
            const data = [];
            for (let i = 0; i < value.length; i++) {
              const element = value[i];
              if (i === index) {
                element[id] = val;
              }
              data.push(element);
            }

            setValue(data);
          }}
          value={value[index][id] || ""}
          placeholder={placeholder}
          required={required}
          isConcat={isConcat}
        />
      )}
      {["2", "3"].includes(type) && value[index][id] && (
        <CustomInput
          type={2 ? "number" : "date"}
          onChange={(e) => {
            const data = [];
            for (let i = 0; i <= value.length; i++) {
              const element = value[i];
              if (i === index) {
                element[id] = e.target.value;
              }
              data.push(element);
            }
            setValue(data);
          }}
          value={value[index][id]}
          placeholder={placeholder}
          width={400}
          required={required}
        />
      )}
      {type === "4" && value[index] != null && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomInput
            type={isFile ? "file" : "url"}
            onChange={(e) => {
              const data = [];
              for (let i = 0; i < value.length; i++) {
                const element = value[i];
                if (i === index) {
                  element[id] = e.target.value;
                }
                data.push(element);
              }
              setValue(data);
            }}
            value={value[index][id]}
            placeholder={placeholder}
            width={300}
            required={required}
          />
          <Switch
            checkedChildren="Fichier"
            unCheckedChildren="Url"
            defaultChecked
            onChange={setIsFile}
            style={{ marginLeft: 20 }}
          />
        </div>
      )}
      {type === "5" && value[index] != null && (
        <Checkbox
          value={value[index][`${id}`]}
          onChange={(e: CheckboxChangeEvent) => {
            const data = [];
            for (let i = 0; i < value.length; i++) {
              const element = value[i];
              if (i === index) {
                element[id] = e.target.checked;
              }
              data.push(element);
              setValue(data);
            }
          }}
        />
      )}
    </ContainerInput>
  );
};

const ContainerInput = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: inherit;
  margin-bottom: 10px;
`;
const Title = styled.p`
  font-size: 1.02rem;
  margin-bottom: 0;
  font-weight: 650;
`;
export default InputFieldObj;
