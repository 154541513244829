import React, { useState } from "react";
import {
  CardForm,
  Center,
  CenterScreen,
  Col,
  Row,
} from "../../components/styled/Styled.Container";
import {
  Label,
  LinkHref,
  Paragraph,
  Title2,
  Title4,
} from "../../components/styled/Styled.Typography";
import { TextField } from "../../components/styled/Styled.Form";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/daxium-logo.png";
import { Button, message } from "antd";
import { Logo } from "../../components/styled/Styled.Asset";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "../../api/auth";
import { isEmail } from "class-validator";

// The forgotten password form
export default function ForgetPassword() {
  const [messageApi, contextHolder] = message.useMessage();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const resetPasswordMutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      messageApi.open({
        type: "success",
        content: "Le mot de passe a été rénitialisé, verifier vos mails",
        duration: 2,
      });
      setTimeout(() => {
        navigate("/auth");
        setEmail("");
      }, 2000);
    },
    onError: () => {
      messageApi.open({
        type: "success",
        content:
          "une erreur est survenue lors de la renitialisation du mot de passe, contacté un administrateur.",
        duration: 2,
      });
    },
  });

  const submit = () => {
    if (isEmail(email)) {
      resetPasswordMutation.mutate({ email });
    } else {
      messageApi.open({
        type: "error",
        content: "Veillez entrer une adresse mail valide.",
        duration: 2,
      });
    }
  };

  return (
    <CenterScreen>
      {contextHolder}
      <Col>
        <Logo srcSet="" src={logo} alt="logo-daxium" />

        <CardForm>
          <Center column>
            <Col>
              {" "}
              {/* Le titre du formulaire */}
              <Title2 capitalize primary ml>
                Daxium-connect
              </Title2>
              <Title4>
                Renseignez votre email pour réinitialiser votre mot de passe
              </Title4>
            </Col>

            <Col>
              <Row alignStart width={"90%"}>
                <Label htmlFor="field-pw">Email</Label>
              </Row>

              <Row alignStart width={"90%"}>
                <TextField
                  id="field-pw"
                  autoFocus
                  line
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </Row>
            </Col>

            {/* Le boutou de validation */}
            <Button
              onClick={submit}
              style={{
                width: "85%",
                backgroundColor: "#4E8CCB",
                color: "white",
                textTransform: "uppercase",
                fontWeight: "700",
                fontFamily: "Source Sans Pro",
              }}
            >
              réinitialiser mon mot de passe
            </Button>
            {/* <Button primary>réinitialiser mon mot de passe</Button> */}
          </Center>
        </CardForm>

        {/* Les autres actions */}
        <Center>
          <Paragraph>
            Avez-vous déjà un compte ?{" "}
            <Link to="/auth" className="link-no-decoration">
              <LinkHref primary>Se connecter</LinkHref>
            </Link>
          </Paragraph>
        </Center>
      </Col>
    </CenterScreen>
  );
}
