import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import { Menu, message } from "antd";
import { Input, Space, Button } from "antd";
import ModalProfil from "../../components/ModalProfil";

import { changePassword, refresh } from "../../api/auth";
import { useMutation, useQuery } from "@tanstack/react-query";

export default function Profil() {
  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: refresh,
  });

  const [messageApi, contextHolder] = message.useMessage();

  const userfile =
    "https://cdn4.iconfinder.com/data/icons/famous-character-vol-2-flat/48/Avatar_Famous_Characters-07-256.png";

  const [current, setCurrent] = useState("detail");
  const [show, setShow] = useState(false);
  const [mdp, setMdp] = useState("");
  const [nouveauMdp, setNouveauMdp] = useState("");
  const items = [
    {
      label: "A propos",
      key: "detail",
    },
    {
      label: "Modifier le mot de passe",
      key: "password",
    },
  ];

  const onSelect = (val: any) => {
    setCurrent(val?.key);
  };

  const mutate = useMutation({
    mutationFn: changePassword,
    onSuccess: (data) => {
      setMdp("");
      setNouveauMdp("");
      messageApi.open({
        type: "success",
        content: "Le mot de passe a été modifié avec succès.",
        duration: 2,
      });
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: "L'ancien mot de passe est incorecte.",
        duration: 2,
      });
    },
  });

  const handleChangePassword = () => {
    if (mdp.trim().length < 1) {
      messageApi.open({
        type: "warning",
        content: "Vous n'avez pas rentré l'ancien mot de passe",
        duration: 2,
      });
      return;
    }
    if (nouveauMdp.trim().length < 8) {
      messageApi.open({
        type: "warning",
        content: "Le nouveau mot de passe doit contenir plus de 8 caractères",
        duration: 2,
      });
      return;
    }
    if (mdp.trim() === nouveauMdp.trim()) {
      messageApi.open({
        type: "warning",
        content: "Les deux mots de passe sont identiques",
        duration: 2,
      });

      return;
    }
    if (mdp && nouveauMdp) {
      mutate.mutate({ mdp, nouveauMdp });
    }
  };

  return (
    <>
      {contextHolder}
      {show && <ModalProfil visible={show} change={setShow} />}
      <Header isProfil={true} />
      {userInfo.isSuccess && userInfo.data && (
        <Container>
          {" "}
          <SubContainer>
            <Title>
              {userInfo.data.prenom} {userInfo.data.nom}
            </Title>
            <Button1 onClick={() => setShow(true)}>Modifier le profil</Button1>
          </SubContainer>
          <div style={{ display: "flex" }}>
            <div style={{ minWidth: 300, height: 300 }}>
              <img src={userfile} alt="image-user" />
            </div>
            <div>
              <Menu
                selectedKeys={[current]}
                style={{
                  width: 500,
                }}
                mode="horizontal"
                items={items}
                onSelect={onSelect}
              />
              <div
                style={{
                  height: 254,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: current === "password" ? "center" : "",
                  justifyContent: "flex-start",
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                {current === "detail" && (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title>Nom : {userInfo.data.nom}</Title>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title>Prenom : {userInfo.data.prenom}</Title>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title>Email : {userInfo.data.email}</Title>
                    </div>
                  </>
                )}

                {current === "password" && (
                  <>
                    <ContainerInput direction="horizontal">
                      <Title2>
                        Ancien mot de passe
                        <span style={{ color: "red" }}>*</span> :
                      </Title2>
                      <Input.Password
                        size="large"
                        style={{ height: 40, width: 280 }}
                        placeholder="Ancien mot de passe"
                        value={mdp}
                        onChange={(e) => setMdp(e.target.value)}
                      />
                    </ContainerInput>
                    <ContainerInput direction="horizontal">
                      <Title2>
                        Nouveau mot de passe
                        <span style={{ color: "red" }}>*</span> :
                      </Title2>
                      <Input.Password
                        size="large"
                        style={{ height: 40, width: 280 }}
                        placeholder="Nouveau mot de passe"
                        value={nouveauMdp}
                        onChange={(e) => setNouveauMdp(e.target.value)}
                      />
                    </ContainerInput>
                    <Button
                      onClick={handleChangePassword}
                      loading={mutate.isPending}
                      style={{
                        background: "#4e8ccb",
                        color: "#fff",
                        textTransform: "uppercase",
                      }}
                    >
                      Modifier
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
const Container = styled.div`
  background: rgb(255, 255, 255);
  padding: 10px;
  height: 90vh;
`;
const SubContainer = styled.div`
  background: #4e8ccb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px;
`;
const Title = styled.p`
  margin-left: 12px;
  font-size: 1.2rem;
`;
const Button1 = styled.button`
  width: 180px;
  height: 40px;
  align-items: center;
  background: #4e8ccb;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: 16px;
  padding: 0 2px;
  color: white;
  border: 1px solid white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 20px;
  transition: 1s;
  fontweight: bold;
  fontsize: 1.4em;
  &:hover {
    background: #4e8ddf;
    cursor: pointer;
  }
`;
const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title2 = styled.p`
  font-size: 1.02rem;
  width: 210px;
  text-align: end;
  align-items: left;
`;
