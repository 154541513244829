import React, { useState, useEffect } from "react";
import { Modal, Switch } from "antd";
import { Button, Input, Space, Select, message, Checkbox } from "antd";
import styled from "styled-components";
import { isEmail, isURL } from "class-validator";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { create, getWhiteserver } from "../api/account";
import { getAllOrganisation } from "../api/organisation";

const ModalAccount: React.FC<modalProps> = ({ visible, change }) => {
  const queryClient = useQueryClient();
  // Recuperation des urls
  const WhiteServer = useQuery({
    queryKey: ["whiteserver"],
    queryFn: getWhiteserver,
  });

  // Recuperation des organisation
  const Organisations = useQuery({
    queryKey: ["Organisations"],
    queryFn: getAllOrganisation,
  });
  const [other, setOther] = useState({ label: "Autre", value: "", key: "1" });
  let urls = [other];
  if (WhiteServer.isSuccess && WhiteServer.data) {
    for (let i = 0; i < WhiteServer.data.length; i++) {
      const element = WhiteServer.data[i];
      urls.push({ label: element.nom, value: element.url, key: element.id });
    }
  }
  const organisations = [];
  if (Organisations.isSuccess && Organisations.data) {
    for (let i = 0; i < Organisations.data.length; i++) {
      const element = Organisations.data[i];
      organisations.push({
        label: element.nom,
        value: element.id,
        key: element.id,
      });
    }
  }
  const createMutate = useMutation({
    mutationFn: create,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["Accounts"] });
      messageApi.open({
        type: "success",
        content: "Le compte a été ajouté avec succès",
        duration: 2,
      });
      handleCancel();
    },
    onError: (err: any, status) => {
      if (Number(err["request"]?.status) === 401) {
        messageApi.open({
          type: "warning",
          content: "Url non autorisée. ",
          duration: 2,
        });
      } else {
        messageApi.open({
          type: "warning",
          content: "Une erreur c'est produite lors de l'ajout du compte ",
          duration: 2,
        });
      }
    },
  });

  const [messageApi, contextHolder] = message.useMessage();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [keyApiVisible, setkeyApiVisible] = React.useState(false);
  const [keySecretVisible, setkeySecretVisible] = React.useState(false);
  const [organisation, setOrganisation] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [keyApi, setKeyApi] = useState("");
  const [keySecret, setKeySecret] = useState("");
  const [api, setApi] = useState("");
  const [loading, setLoading] = useState(false);
  // const [actif, setActif] = useState(false);

  const submit = () => {
    try {
      if (!organisation.trim())
        throw new Error("Vous n'avez pas choisi d'organisation");
      if (!name.trim()) throw new Error("Vous n'avez pas entré de nom");
      if (!email.trim())
        throw new Error("Vous n'avez entré pas d'adresse mail");
      if (!isEmail(email.trim()))
        throw new Error("Veillez entrer une adresse mail valide");
      if (!api.trim())
        throw new Error("Vous n'avez entré pas d'url pour  l'api");
      if (!isURL(api.trim())) throw new Error("Veillez entrer une URL valide");
      if (!password.trim())
        throw new Error("Vous n'avez pas entré de mot de passe");

      if (!keyApi.trim()) throw new Error("Vous n'avez pas entré de Clé API");
      if (!keySecret.trim())
        throw new Error("Vous n'avez pas entré de Clé Secrete");
    } catch (e: any) {
      messageApi.open({
        type: "warning",
        content: e.message ? e.message : "",
        duration: 2,
      });
      return;
    }
    const verif =
      organisation && name && password && keyApi && keySecret && email && api;

    if (verif) {
      createMutate.mutate({
        idOrganisation: organisation,
        nom: name,
        email,
        api: retirerDernierSlash(api),
        mdp: password,
        cleApi: keyApi,
        cleSecret: keySecret,
      });
    }
  };
  const handleOk = () => {
    clearData();
    change(false);
  };
  const retirerDernierSlash = (chaine: string): string => {
    // Trim de la chaîne de caractères
    chaine = chaine.trim();

    // Vérifie si la chaîne est vide après le trim
    if (chaine.length === 0) {
      return chaine;
    }

    // Vérifie si le dernier caractère est un '/'
    if (chaine.charAt(chaine.length - 1) === "/") {
      // Retire le dernier caractère
      return chaine.slice(0, -1);
    } else {
      return chaine;
    }
  };

  const handleCancel = () => {
    clearData();
    change(false);
  };
  // Cleaning the form
  const clearData = () => {
    setName("");
    setEmail("");
    setOrganisation("");
    setPassword("");
    setKeyApi("");
    setKeySecret("");
    setApi("");
    setLoading(false);
    setOther({ label: "Autre", value: "", key: "1" });
  };
  return (
    <Modal
      style={{ top: 20 }}
      title=""
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      centered
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            key="back"
            size={"large"}
            onClick={handleCancel}
            style={{
              fontSize: "1rem",
              padding: "5px",
              textAlign: "center",
              width: "120px",
            }}
          >
            ANNULER
          </Button>
          <Button
            key="submit"
            size={"large"}
            style={{
              background: "#4e8ccb",
              color: "white",
              fontSize: "1rem",
              paddingLeft: "13px",
              paddingRight: "13px",
              textAlign: "center",
              width: "auto",
            }}
            loading={createMutate.isPending}
            onClick={submit}
          >
            AJOUTER LE COMPTE
          </Button>
        </div>,
      ]}
    >
      <div style={{ padding: "10px" }}>
        <Content>CREATION DU COMPTE</Content>
        <Container>
          <ContainerInput direction="horizontal">
            <Title>
              Organisation <span style={{ color: "red" }}>*</span> :{" "}
            </Title>
            <Select
              placeholder="Choisissez votre organisation"
              showSearch={true}
              optionFilterProp="label"
              style={{ width: 360 }}
              size="large"
              value={organisation}
              onChange={setOrganisation}
              options={organisations}
              loading={Organisations.isLoading}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Nom du compte <span style={{ color: "red" }}>*</span> :
            </Title>
            <Input
              placeholder="Saisir le nom du compte "
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="large"
              style={{ height: 40, width: 360 }}
            />
          </ContainerInput>
          {/* <ContainerInput direction="horizontal">
            <Title>Actif : </Title>
            <Checkbox
              style={{ height: 20, width: 360 }}
              checked={actif}
              onClick={() => setActif((actif) => !actif)}
            />
          </ContainerInput> */}
          <ContainerInput direction="horizontal">
            <Title>
              Email <span style={{ color: "red" }}>*</span> :
            </Title>
            <Input
              placeholder="Saisir votre adresse email "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
              style={{ height: 40, width: 360 }}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Url Api <span style={{ color: "red" }}>*</span> :
            </Title>
            <Select
              placeholder="Choisir votre une url"
              showSearch={true}
              optionFilterProp="label"
              style={{ width: 360 }}
              size="large"
              value={api}
              onChange={(val) => {
                setApi(val);
              }}
              options={urls}
              loading={WhiteServer.isLoading}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title></Title>

            <Input
              value={api}
              disabled={Boolean(
                urls?.find((u) => u.value === api)?.key !== "1"
              )}
              onChange={(e) => {
                const el = urls?.find((u) => u.value === e.target.value);
                if (el) {
                  setApi(el.value);
                } else {
                  setOther({ label: "Autre", value: e.target.value, key: "1" });
                  setApi(e.target.value);
                }
              }}
              size="large"
              placeholder="Saisir l'adresse url"
              style={{ height: 40, width: 360 }}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Mot de passe <span style={{ color: "red" }}>*</span> :
            </Title>
            <Input.Password
              size="large"
              placeholder="Saisir le mot de passe"
              style={{ height: 40, width: 360 }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Clé API <span style={{ color: "red" }}>*</span> :{" "}
            </Title>
            <Input.Password
              size="large"
              placeholder="Saisir la Clé API"
              style={{ height: 40, width: 360 }}
              value={keyApi}
              onChange={(e) => setKeyApi(e.target.value)}
              visibilityToggle={{
                visible: keyApiVisible,
                onVisibleChange: setkeyApiVisible,
              }}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Clé Secrete <span style={{ color: "red" }}>*</span> :{" "}
            </Title>
            <Input.Password
              size="large"
              placeholder="Saisir la Clé Secrete"
              style={{ height: 40, width: 360 }}
              value={keySecret}
              onChange={(e) => setKeySecret(e.target.value)}
              visibilityToggle={{
                visible: keySecretVisible,
                onVisibleChange: setkeySecretVisible,
              }}
            />
          </ContainerInput>
        </Container>
      </div>
      {contextHolder}
    </Modal>
  );
};

export default ModalAccount;
const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-size: 1.02rem;
  width: 150px;
  text-align: end;
  align-items: left;
  font-weight: 650;
`;

const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  text-tranform: uppercase;
  border-radius: 5px;
  padding: 3px;
`;
interface modalProps {
  visible: boolean;
  change: Function;
}
