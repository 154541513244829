import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthState } from "../models/auth.model";
import { RootState } from "./store";

const initialState: AuthState = {
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setIsAuthenticated } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth.isAuthenticated;

export default authSlice.reducer;
