import React, { useEffect } from "react";
import { CenterScreen, Col } from "../../components/styled/Styled.Container";
import styled from "styled-components";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function ExpirateLink() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("token");

    if (token && user) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <CenterScreen>
      <Col
        style={{
          background: "#fff",
          width: 300,
          borderRadius: "5px",
          padding: 10,
        }}
      >
        <p style={{ fontSize: 20, fontWeight: "bold", paddingBottom: 10 }}>
          L'invitation a expiré
        </p>
        <Button
          style={{ background: "#4e8ccb", color: "white" }}
          onClick={() => navigate("/auth")}
        >
          Se connecter
        </Button>
      </Col>
    </CenterScreen>
  );
}
const Container = styled.d;
