import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import { Select, ConfigProvider } from "antd";
import { getAllOrganisation } from "../../api/organisation";
import { useQuery } from "@tanstack/react-query";
import frFr from "antd/lib/locale/fr_FR";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import { getReportByUser } from "../../api/report";
import { Report, ReportConnecteur } from "../../models/Report";
import TableCustom from "../../components/TableCustom";
import btnTop from "../../assets/btnTop.svg";
import btnDown from "../../assets/btnDown.svg";
import rond from "../../assets/rond.svg";
import transaction from "../../assets/transaction.svg";
import ok from "../../assets/ok.svg";
import info from "../../assets/info.svg";
import type { ColumnsType } from "antd/es/table";
import { getAll } from "../../api/connector";
import { ConnectorResponse } from "../../interface/connectors";

export default function Consommation() {
  const connectorsInfos = useQuery({
    queryKey: ["Connectors"],
    queryFn: getAll,
  });

  const Organisations = useQuery({
    queryKey: ["Organisations"],
    queryFn: getAllOrganisation,
  });

  const getReport = useQuery({
    queryKey: ["ReportByUser"],
    queryFn: getReportByUser,
    enabled: connectorsInfos.isSuccess,
  });

  const [dataOrganisation, setDataOrganisation] =
    useState<OrganizationData | null>(null);

  const getUniqueMonths = (reports: Report[]): OrganizationData[] => {
    const organizationDataMap: Map<string, OrganizationData> = new Map();
    const monthOrder: { [key: string]: number } = {};

    // Générer l'ordre des mois en fonction de la date actuelle
    const currentDate = new Date();
    const localeMonths = Array.from({ length: 12 }, (_, index) => {
      const date = new Date(currentDate.getFullYear(), index, 1);
      return date.toLocaleString("default", { month: "long" });
    });

    localeMonths.forEach((month, index) => {
      monthOrder[month.toLowerCase()] = index + 1;
    });

    reports.forEach((report) => {
      const startDate = new Date(+report.dateDebut);
      const endDate = new Date(+report.dateFin);

      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const monthYearStr = currentDate.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        const [month, year] = monthYearStr.split(" ");

        const monthYear: MonthYear = { month, year };
        const organizationData = organizationDataMap.get(
          report.idOrganisation
        ) || { idOrganisation: report.idOrganisation, data: [] };
        const monthData = organizationData.data.find(
          (data) =>
            data.monthYear.month === month && data.monthYear.year === year
        );

        if (!monthData) {
          organizationData.data.push({
            monthYear,
            reportConnecteurs: report.reportConnecteurs,
          });
        } else {
          monthData.reportConnecteurs.push(...report.reportConnecteurs);
        }

        organizationDataMap.set(report.idOrganisation, organizationData);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    });

    organizationDataMap.forEach((organizationData) => {
      organizationData.data.sort(
        (a, b) =>
          monthOrder[a.monthYear.month.toLowerCase()] -
          monthOrder[b.monthYear.month.toLowerCase()]
      );
    });

    return Array.from(organizationDataMap.values());
  };

  const [organisation, setOrganisation] = useState<string | null>(null);

  const [months, setMonths] = useState<
    {
      id: number;
      month: string;
      year: string;
      options: ReportConnecteur[];
    }[]
  >([]);

  const [filteredMonths, setFilteredMonths] = useState<
    {
      id: number;
      month: string;
      year: string;
      options: ReportConnecteur[];
    }[]
  >([]);

  const [selected, setSelected] = useState<{
    id: number;
    month: string;
    year: string;
    options: ReportConnecteur[];
  } | null>(null);

  const currentYear = new Date().getFullYear();

  const [year, setYear] = useState<number>(currentYear);

  let AllDataOrganisation: OrganizationData[] = [];
  let connectors: ConnectorResponse[] = [];
  if (connectorsInfos.data) {
    connectors = connectorsInfos.data;
  }
  if (getReport.data) {
    AllDataOrganisation = getUniqueMonths(getReport.data);
  }

  dayjs.locale("fr");

  useEffect(() => {
    const id = localStorage.getItem("idOrganisation");
    setOrganisation(id);
  }, []);

  useEffect(() => {
    if (
      AllDataOrganisation.length > 0 &&
      organisation &&
      connectors.length > 0
    ) {
      const data =
        AllDataOrganisation.find(
          (item) => item.idOrganisation === organisation
        ) || null;
      const AllMonth =
        data?.data.map((item, index) => {
          return {
            id: index,
            ...item.monthYear,

            options: item.reportConnecteurs.filter((item) => {
              if (item.connecteur.privee && +item.totalTransaction === 0) {
                return false;
              }
              return true;
            }),
          };
        }) || [];

      const filterData = AllMonth.filter((item) => +item.year === +year);
      if (filterData.length > 0) {
        setSelected(filterData[0]);
      }
      setDataOrganisation(data);
      setMonths(AllMonth || []);
      setFilteredMonths(filterData);
    }
  }, [organisation, getReport.isSuccess]);

  useEffect(() => {
    if (
      Organisations.data &&
      Organisations.data.length > 0 &&
      !localStorage.getItem("idOrganisation")
    ) {
      setOrganisation(Organisations.data[0].id);
    }
  }, [Organisations.isSuccess]);

  const data = Organisations.data
    ? Organisations.data.map((item) => {
        return {
          label: item.nom,
          value: item.id,
        };
      })
    : [];

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    const filterData = months.filter((item) => +item.year === +dateString);
    setYear(+dateString);
    setFilteredMonths(filterData);
    if (filterData.length > 0) {
      setSelected(filterData[0]);
    } else {
      setSelected(null);
    }
  };

  const columns: ColumnsType<any[]> = [
    {
      title: "Connecteur",
      dataIndex: "connecteur",
      ellipsis: true,
      width: 290,
      render: (item) => {
        return (
          <div style={{ display: "flex", textAlign: "center", gap: 10 }}>
            <Image src={item.image} alt={item.nom} />
            <p style={{ textAlign: "left", marginTop: 2 }}>{item.nom}</p>
          </div>
        );
      },
    },
    {
      title: "Jetons",
      dataIndex: "totalCount",
    },
    {
      title: "Transaction",
      dataIndex: "totalTransaction",
      align: "center",
    },
    {
      title: "Succès",
      dataIndex: "totalOk",
      align: "center",
    },
    {
      title: "Erreur",
      dataIndex: "totalKo",
      align: "center",
    },
    {
      title: "En Attente",
      dataIndex: "totalWait",
      align: "center",
    },
  ];

  const getName = (id: string) => {
    const connector = connectors.find((item) => item.id === id);
    return { image: connector?.image, name: connector?.nom };
  };

  return (
    <>
      <Header isConsumption={true} />
      <Container>
        <SubContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
            }}
          >
            <Title>Organisation : </Title>
            <Select
              options={data}
              style={{
                height: 40,
                width: 200,
                marginBottom: "-10px",
              }}
              value={organisation}
              optionFilterProp="label"
              onChange={(val: string) => {
                localStorage.setItem("idOrganisation", val);
                setOrganisation(val);
              }}
            />
          </div>
          <ConfigProvider locale={frFr}>
            <DatePicker
              style={{ width: 100 }}
              onChange={onChange}
              picker="year"
              placeholder="date"
              defaultValue={dayjs(year.toString(), "YYYY")}
            />
          </ConfigProvider>
        </SubContainer>

        <div
          style={{
            width: "100%",
            height: "90%",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "95%",
              // marginTop: "10px",
              display: "flex",
              gap: 20,
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <p style={{ textAlign: "left", marginLeft: 16 }}>
                Mois de consommation
              </p>
              <CardContainer isScroll={true}>
                <div>
                  {filteredMonths.map((item, index) => (
                    <Card
                      key={index}
                      selected={selected?.id === item.id}
                      onClick={() => {
                        setSelected(item);
                      }}
                    >
                      <CardMonth
                        key={index}
                        month={item.month}
                        year={item.year}
                        options={item.options}
                      />
                    </Card>
                  ))}
                </div>
              </CardContainer>
            </div>
            <div style={{ width: "70%" }}>
              <p style={{ textAlign: "left" }}>
                Consommation sur le mois de {selected?.month} {selected?.year}
              </p>
              <CardContainer isScroll={false}>
                <TableCustom
                  columns={columns}
                  dataSource={selected?.options || []}
                  pageSize={8}
                  loading={false}
                />
              </CardContainer>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

const CardMonth: React.FC<propsCard> = ({ month, year, options }) => {
  const getTotalCountSum = (reportConnectors: ReportConnecteur[]): number => {
    let total = 0;
    for (let i = 0; i < reportConnectors.length; i++) {
      const element = reportConnectors[i];
      total += element.totalCount;
    }
    return total;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p>
        {month} {year}
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 40,
        }}
      >
        <Image src={rond} alt={"name"} />
        {getTotalCountSum(options)}
      </div>
    </div>
  );
};

interface propsCard {
  month: string;
  year: string;

  options: ReportConnecteur[];
}

const Container = styled.div`
  background: rgb(255, 255, 255);
  padding: 8px;
  height: 90vh;
  display: "flex";
`;

const SubContainer = styled.div`
  background: #4e8ccb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px;
  padding-right: 15px;
`;

const Title = styled.p`
  margin-left: 12px;
  font-size: 1.45rem;
`;

const CardContainer = styled.div<containerProps>`
  width: 100%;
  height: 100%;
  // margin-top: 2px;
  overflow-y: ${({ isScroll }) => (isScroll ? "auto" : "hidden")};
  // overflow-x: hidden;
  box-sizing: border-box;
  // border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

interface MonthYear {
  month: string;
  year: string;
}

interface MonthYear {
  month: string;
  year: string;
}

const Image = styled.img`
  height: 25px;
  flex-shrink: 0;
`;

const Card = styled.div<CardProps>`
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#e6f7ff" : "#f9f9f9")};
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 16px;
  box-shadow: ${({ selected }) =>
    selected
      ? "0px 4px 8px rgba(0, 0, 0, 0.2)"
      : "0px 4px 8px rgba(0, 0, 0, 0.1)"};

  /* Effet de survol */
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  &:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

interface CardProps {
  selected: boolean;
}

interface OrganizationData {
  idOrganisation: string;
  data: {
    monthYear: MonthYear;
    reportConnecteurs: ReportConnecteur[]; // Remplacez any par le type approprié de reportConnecteurs
  }[];
}

interface containerProps {
  isScroll: boolean;
}
