import React, { useState } from "react";
import { Modal } from "antd";
import { Button, Input, Space, message } from "antd";
import styled from "styled-components";
import { updateProfil, refresh } from "../api/auth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const ModalProfil: React.FC<modalProps> = ({ visible, change }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();

  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: refresh,
  });

  const mutate = useMutation({
    mutationFn: updateProfil,
    onSuccess: (data) => {
      messageApi.open({
        type: "success",
        content: "Modification réalisée avec succès.",
        duration: 2,
      });
      queryClient.invalidateQueries({ queryKey: ["userInfo"] });
      handleCancel();
    },
    onError: (err) => {
      messageApi.open({
        type: "warning",
        content: "une erreur est survenue lors de la mise à jour.",
        duration: 2,
      });
    },
  });

  const [nom, setNom] = useState<string>(userInfo.data?.nom || "");
  const [prenom, setPrenom] = useState<string>(userInfo.data?.prenom || "");
  const [email, setEmail] = useState<string>(userInfo.data?.email || "");

  const handleOk = () => {
    change(false);
    clearData();
  };

  const submit = () => {
    try {
      if (!nom.trim()) throw new Error("Vous n'avez pas entrer de nom");
      if (!prenom.trim()) throw new Error("Vous n'avez pas entrer de prenom");
      if (!email.trim()) throw new Error("Vous n'avez pas entrer d'email");
    } catch (e: any) {
      messageApi.open({
        type: "warning",
        content: e.message ? e.message : "",
        duration: 2,
      });
      return;
    }
    if (nom && prenom && email && userInfo.data) {
      mutate.mutate({ idUser: userInfo.data?.id, nom, prenom, email });
    }
  };

  const handleCancel = () => {
    change(false);
    clearData();
  };

  // Cleaning the form
  const clearData = () => {
    setNom("");
    setPrenom("");
    setEmail("");
  };

  return (
    <Modal
      style={{ top: 20 }}
      title=""
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={560}
      centered
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            key="back"
            size={"large"}
            onClick={handleCancel}
            style={{
              fontSize: "1rem",
              padding: "5px",
              textAlign: "center",
              width: "120px",
            }}
          >
            ANNULER
          </Button>
          <Button
            key="submit"
            size={"large"}
            style={{
              background: "#4e8ccb",
              color: "white",
              fontSize: "1rem",
              paddingLeft: "13px",
              paddingRight: "13px",
              textAlign: "center",
              width: "auto",
            }}
            loading={mutate.isPending}
            onClick={submit}
          >
            Modifier
          </Button>
        </div>,
      ]}
    >
      <div style={{ padding: "10px" }}>
        <Content>Modifier mon profil</Content>
        <Container>
          <ContainerInput direction="horizontal">
            <Title>
              Nom <span style={{ color: "red" }}>*</span> :
            </Title>
            <Input
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              size="large"
              style={{ height: 40, width: 300 }}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Prenom <span style={{ color: "red" }}>*</span> :
            </Title>
            <Input
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              style={{ height: 40, width: 300 }}
            />
          </ContainerInput>
          <ContainerInput direction="horizontal">
            <Title>
              Email <span style={{ color: "red" }}>*</span> :
            </Title>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
              style={{ height: 40, width: 300 }}
            />
          </ContainerInput>
        </Container>
      </div>
      {contextHolder}
    </Modal>
  );
};

export default ModalProfil;
const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-size: 1.02rem;
  width: 150px;
  text-align: end;
  align-items: left;
  font-weight: 650;
`;

const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  text-tranform: uppercase;
  border-radius: 5px;
  padding: 3px;
`;
interface modalProps {
  visible: boolean;
  change: Function;
  update?: boolean;
  data?: any;
}
