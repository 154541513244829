import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { UserState } from "../models/user.model";

const initialState: UserState = {
  id: "",
  email: "",
  nom: "",
  prenom: "",
  organisations: [],
  createdDate: "",
  updatedDate: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.nom = action.payload.nom;
      state.prenom = action.payload.prenom;
      state.organisations = action.payload.organisations;
    },
  },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
