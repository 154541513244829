import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../components/Sidebar";

const Container = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  weight: 100vw;
`;

const Layout = styled.div`
  height: 100vh;
  padding: 8px;
  margin-left: 80px;
  min-width: 890px;
  // gap: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export default function Dashboard() {
  return (
    <Container>
      <Sidebar />
      <Layout>
        <Outlet />
      </Layout>
    </Container>
  );
}
