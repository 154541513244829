import { styled } from "styled-components";

// The Daxium logo
export const Logo = styled.img<{ primary?: boolean }>`
  height: 90px;
  width: 90px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 15px;
`;
