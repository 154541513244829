import ConfigConnector from "../pages/Connector";
import Home from "../pages/Home";
import Account from "../pages/Account";
import Profil from "../pages/Profile";
import Organisation from "../pages/Organisation";
import Drive from "../pages/Drive";
import Consommation from "../pages/Consommation";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "account",
    element: <Account />,
  },

  {
    path: "config-connector/:id",
    element: <ConfigConnector />,
  },
  {
    path: "profil",
    element: <Profil />,
  },
  {
    path: "organisation",
    element: <Organisation />,
  },
  {
    path: "drive",
    element: <Drive />,
  },
  {
    path: "consommation",
    element: <Consommation />,
  },
];
export default routes;
