import { styled } from "styled-components";

// Texte niveau 2 : H2
export const Title2 = styled.strong<{
  primary?: boolean;
  capitalize?: boolean;
  ml?: boolean;
}>`
  font-size: 22px;
  color: ${(props) => (props.primary ? "#4E8CCB" : "#000")};
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "normal")};
  margin-left: ${(props) => (props.ml ? "3px" : "0px")};
  font-weight: 900;

  font-family: Poppins, sans-serif;
  margin-bottom: 0.5em;
`;

// Text level 3
export const Title4 = styled.strong<{
  primary?: boolean;
  capitalize?: boolean;
  ml?: boolean;
}>`
  font-size: 14px;
  color: ${(props) => (props.primary ? "#4E8CCB" : "#000")};
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "normal")};
  margin-left: ${(props) => (props.ml ? "3px" : "0px")};
  font-weight: 600;
  font-family: Poppins, sans-serif;
  padding: 0px 2px;
`;

// Label of a form field
export const Label = styled.label`
  font-size: 16px;
  color: gray;
  font-weight: 600;
  margin-bottom: 8px;

  font-family: Poppins, sans-serif;
`;

// Primary Class Links
export const LinkHref = styled.span<{ primary?: boolean }>`
  font-size: 14px;
  font-weight: 550;

  font-family: Poppins, sans-serif;
  color: ${(props) => (props.primary ? "var(--primary)" : "#000")};
`;

// The paragraph
export const Paragraph = styled.p`
  font-family: Poppins, sans-serif;
  font-weight: 550;
  color: #000;
  font-size: 15px;
`;
