import React, { useEffect, useState } from "react";
import styled from "styled-components";
import filter from "../../assets/filter.svg";
import { Select } from "antd";
import Connector from "../Connector/Connector";
import Header from "../../components/Header";

import { refresh } from "../../api/auth";
import { useQuery } from "@tanstack/react-query";
import { getAll } from "../../api/connector";
import { Spin } from "antd";

export default function Home() {
  const [categorie, setCategorie] = useState("tous");

  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: refresh,
  });

  const connectorsInfos = useQuery({
    queryKey: ["Connectors"],
    queryFn: getAll,
  });

  return (
    <>
      <Header isHome={true} />
      <Container>
        <Category>
          <SubHeader>
            <Img src={filter} alt="filter" />
            <Label>Filtrer par catégorie :</Label>
            <Select
              showSearch={true}
              optionFilterProp="label"
              defaultValue="tous"
              value={categorie}
              style={{
                textAlign: "center",
                marginLeft: "40px",
                width: "100px",
              }}
              options={[
                {
                  value: "tous",
                  label: "Tous",
                },
                {
                  value: "import",
                  label: "Import",
                },
              ]}
              onChange={setCategorie}
            />
          </SubHeader>
          <ContainerBody>
            <Spin spinning={connectorsInfos.isLoading} size="large">
              <Connectors>
                {connectorsInfos.isSuccess && connectorsInfos.data && (
                  <>
                    {categorie === "tous"
                      ? connectorsInfos.data.map((connector: any) => {
                          return (
                            <Connector
                              key={connector.id}
                              id={connector.id}
                              imageUrl={connector.image}
                              label={connector.nom}
                            />
                          );
                        })
                      : connectorsInfos.data
                          .filter((connecteur: any) => connecteur.peutImporter)
                          .map((connector: any) => {
                            return (
                              <Connector
                                key={connector.id}
                                id={connector.id}
                                imageUrl={connector.image}
                                label={connector.nom}
                              />
                            );
                          })}
                  </>
                )}
              </Connectors>
            </Spin>
            <ContainerVideo>
              <span> Comment utiliser le connecteur excel ?</span>
              <video controls width="70%" height="307">
                <source
                  src={
                    "https://dc-store.s3.eu-west-3.amazonaws.com/videos/My+Video.mp4"
                  }
                  type="video/webm"
                />
              </video>
            </ContainerVideo>
          </ContainerBody>
        </Category>
      </Container>
    </>
  );
}
const Container = styled.div`
  padding: 10px;
  height: 90vh;
`;
const Category = styled.div`
  color: var(--body-text, #64727e);
  margin: 0;
`;
const Img = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;
const Label = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin-right: 5px;
`;
const Connectors = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-width: 600px;
`;
const ContainerVideo = styled.div`
  width: 553px;
  height: 350px;
  padding: 2px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
`;
const ContainerBody = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const SubHeader = styled.div`
  display: flex;
  align-items: center;
`;
