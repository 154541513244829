import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import TableCustom from "../../components/TableCustom";
import type { ColumnsType } from "antd/es/table";
import { FcInfo } from "react-icons/fc";
// import { BarChartOutlined } from "react-icons/b";
import { Divider, Modal, Spin } from "antd";
import ModalInvitedUser from "../../components/ModalInvitedUser";
import { BsFillTrashFill } from "react-icons/bs";
import { AiOutlineUserAdd } from "react-icons/ai";
import { getAllOrganisation, removeUsers } from "../../api/organisation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { refresh } from "../../api/auth";
import { UtilisateurOrganisationResponse } from "../../interface/Organisation";
import { TfiStatsUp } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";

export default function Organisation() {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const Organisations = useQuery({
    queryKey: ["Organisations"],
    queryFn: getAllOrganisation,
  });

  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: refresh,
  });
  let isAdmin = false;

  if (userInfo?.data) {
    isAdmin = ["ADMIN", "SUPER_ADMIN"].includes(userInfo.data.role);
  }
  const [organisation, setOrganisation] = useState<selected>({
    label: "",
    value: "",
  });

  const data = Organisations.data
    ? Organisations.data.map((item) => {
        return {
          key: item.id,
          id: item.id,
          idProprietaire: item.idProprietaire,
          nom: item.nom,
          code: item.code,
          siret: item.siret,
          utilisateurs: item.utilisateurs,
          nbUser: item.utilisateurs?.length | 0,
        };
      })
    : [];

  const columns: ColumnsType<DataType> = [
    {
      title: "Nom",
      dataIndex: "nom",
      ellipsis: true,
    },
    {
      title: "Code",
      dataIndex: "code",
      ellipsis: true,
    },

    {
      title: "Siret",
      dataIndex: "siret",
      ellipsis: true,
    },

    {
      title: "Nombre d'utilisateur(s)",
      dataIndex: "nbUser",
      align: "center",
    },
    {
      title: "Actions",
      width: 120,
      dataIndex: "message",
      align: "center",
      render: (text, record: DataType) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "auto",
            }}
          >
            {/* <BarChartOutlined /> */}
            <FcInfo
              style={{ cursor: "pointer" }}
              onClick={() => info(record)}
            />
            {(userInfo.data?.id === record?.idProprietaire || isAdmin) && (
              <AiOutlineUserAdd
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOrganisation({
                    label: record.nom,
                    value: record.id,
                  });
                  setShow(true);
                }}
              />
            )}

            <TfiStatsUp
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.setItem("idOrganisation", record.id);
                navigate("/consommation");
              }}
            />
          </div>
        );
      },
    },
  ];
  const removeUserMutation = useMutation({
    mutationFn: removeUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Organisations"] });
    },
    onError: () => {},
  });
  const info = (organisation: DataType) => {
    Modal.info({
      open: modal,
      title: ``,
      centered: true,
      maskClosable: true,
      keyboard: true,

      content: (
        <div>
          <Spin size="large" spinning={removeUserMutation.isPending}>
            <p>Nom : {organisation.nom} </p>
            <p>Code : {organisation.code} </p>
            <p>Siret : {organisation.siret} </p>
            <p>nbUser : {organisation.nbUser} </p>
            <Divider />
            {organisation.utilisateurs.map((user) => {
              return (
                <div
                  key={user.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{user.email}</p>
                  {organisation.idProprietaire === userInfo.data?.id ||
                    (isAdmin && (
                      <div
                        onClick={() => {
                          removeUserMutation.mutate({
                            emails: [user.email],
                            idOrganisation: organisation.id,
                          });
                          setModal(false);
                        }}
                      >
                        <BsFillTrashFill style={{ cursor: "pointer" }} />
                      </div>
                    ))}
                </div>
              );
            })}
          </Spin>
        </div>
      ),
      onOk() {},
      onCancel() {},
    });
  };

  return (
    <>
      <ModalInvitedUser
        visible={show}
        change={setShow}
        organisation={organisation}
      />
      <Header isOrganisation={true} />
      <Container>
        <SubContainer>
          <Title>Liste de mes organisations associées à Daxium- Air </Title>
        </SubContainer>
        <TableCustom
          columns={columns}
          dataSource={data}
          pageSize={8}
          loading={Organisations.isLoading || removeUserMutation.isPending}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  background: rgb(255, 255, 255);
  padding: 10px;
  height: 90vh;
`;

const SubContainer = styled.div`
  background: #4e8ccb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px;
`;

const Title = styled.p`
  margin-left: 12px;
  font-size: 1.45rem;
`;

interface DataType {
  key: React.Key;
  nom: string;
  nbUser: number;
  id: string;
  code: string;
  siret: string;
  utilisateurs: UtilisateurOrganisationResponse[];
  idProprietaire: string;
}

interface selected {
  label: string;
  value: string;
}
