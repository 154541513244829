import api from ".";
import { ConnectorResponse } from "../interface/connectors";

const getAll = async () => {
  const res = await api.get("/connecteur/organisation");
  const response: ConnectorResponse[] = JSON.parse(res.data);
  return response;
};

const getOneConnector = async ({
  queryKey,
}: {
  queryKey: ConnectorQueryKey;
}) => {
  const [_, idConnector] = queryKey;
  const res = await api.get("/connecteur/" + idConnector);
  const response: ConnectorResponse = JSON.parse(res.data);
  return response;
};

export { getAll, getOneConnector };
type ConnectorQueryKey = ["connector", string];
