import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmail } from "class-validator";
import {
  CardForm,
  Center,
  CenterScreen,
  Col,
  Row,
} from "../../components/styled/Styled.Container";
import {
  LinkHref,
  Title2,
  Title4,
} from "../../components/styled/Styled.Typography";
import { Button, message } from "antd";
import { Link } from "react-router-dom";
import { Logo } from "../../components/styled/Styled.Asset";
import logo from "../../assets/daxium-logo.png";
import TypeEmail from "./loginStep/TypeEmail";
import TypePassword from "./loginStep/TypePassword";

import { login } from "../../api/auth";
import { useMutation } from "@tanstack/react-query";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isStepPassword, setisStepPassword] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("utilisateur");
    if (token && user) {
      navigate("/");
    }
  }, [navigate]);

  const mutate = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      messageApi.open({
        type: "success",
        content: "Vous êtes connectés, Bienvenue sur Daxium-Connect",
        duration: 2,
      });
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.utilisateur));
      messageApi.open({
        type: "success",
        content: "Vous êtes connectés, Bienvenue sur Daxium-Connect",
        duration: 2,
      });
      navigate("/");
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content:
          "Mauvais email et/ou mot de passe.  contactez votre administrateur",
        duration: 3,
      });
    },
  });

  const handleLogin = () => {
    if (!isStepPassword) {
      if (isEmail(email)) {
        setisStepPassword(true);
      } else {
        messageApi.open({
          type: "warning",
          content: "Entrez une adresse mail valide",
          duration: 3,
        });
      }
    } else {
      if (password) {
        mutate.mutate({ email: email, mdp: password });
      } else {
        messageApi.open({
          type: "warning",
          content: "Le mot de passe ne peut pas être vide",
          duration: 2,
        });
      }
    }
  };

  return (
    <CenterScreen>
      {contextHolder}
      <Col>
        {" "}
        {/* Le Logo de la plateforme  */}
        <Logo srcSet="" src={logo} alt="logo-daxium" />
        <CardForm loading={mutate.isPending && isStepPassword}>
          <Center column>
            <Col>
              {" "}
              {/* Le titre du formulaire */}
              <Title2>
                Bienvenue sur
                <Title2 capitalize primary ml>
                  Daxium-connect
                </Title2>
              </Title2>
              <Title4>Renseignez vos informations de connexion</Title4>
            </Col>

            {/* LES ETAPES DE CONNEXION AU COMPTE */}

            {isStepPassword ? (
              <TypePassword
                onClick={handleLogin}
                value={password}
                setValue={setPassword}
                email={email}
                prevStep={() => setisStepPassword(false)}
              />
            ) : (
              <TypeEmail
                value={email}
                setValue={setEmail}
                onClick={handleLogin}
              />
            )}

            <Row width={"90%"}>
              <Link to="/forget-password" className="link-no-decoration">
                <LinkHref primary>Mot de passe oublié</LinkHref>
              </Link>
            </Row>
            <Button
              onClick={handleLogin}
              style={{
                width: "85%",
                backgroundColor: "#4E8CCB",
                color: "white",
                textTransform: "uppercase",
                fontWeight: "700",
                fontFamily: "Source Sans Pro",
              }}
              loading={mutate.isPending && isStepPassword}
            >
              {isStepPassword ? "Soumettre" : "Suivant"}
            </Button>
          </Center>
        </CardForm>
        {/* Pour créer un compte */}
        <Center>
          {/* <Paragraph>
            Pas de compte ?{" "}
            <Link to="" className="link-no-decoration">
              <LinkHref primary>S'inscrire maintenant</LinkHref>
            </Link>
          </Paragraph> */}
        </Center>
      </Col>
    </CenterScreen>
  );
}
