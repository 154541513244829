import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import logoutImg from "../assets/logout.png";
import group from "../assets/group.svg";
import accueil from "../assets/Frame 13.svg";
import compte from "../assets/Comptes.svg";
import stockage from "../assets/stockage.svg";
import stats from "../assets/stats.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface propsNavItem {
  image: string;
  name: string;
}

const NavItem: React.FC<propsNavItem> = ({ image, name }) => {
  return (
    <Item>
      <Image src={image} alt={name} />
      <Span>{name}</Span>
    </Item>
  );
};

export default function Sidebar() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth");
  };
  return (
    <Container>
      <Element>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Logo src={logo} alt="logo" />
        </Link>

        <Link to="/" style={{ textDecoration: "none" }}>
          <NavItem image={accueil} name="Accueil" />
        </Link>
        <Link to="/account" style={{ textDecoration: "none" }}>
          <NavItem image={compte} name="Compte" />
        </Link>
        <Link to="/organisation" style={{ textDecoration: "none" }}>
          <NavItem image={group} name="Organisation" />
        </Link>
        <Link to="/drive" style={{ textDecoration: "none" }}>
          <NavItem image={stockage} name="Mes Drives" />
        </Link>
        <Link to="/consommation" style={{ textDecoration: "none" }}>
          <NavItem image={stats} name="Mes Stats" />
        </Link>
      </Element>
      <Logout src={logoutImg} alt="logout" onClick={handleLogout} />
    </Container>
  );
}

const Container = styled.div`
  background-color: #2d73af;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 80px;
  min-width: 80px;
  position: fixed;
  overflow: auto;
  overflow: hidden;
`;

const Logo = styled.img`
  background-color: #2d73af;
  cursor: pointer;
  margin-bottom: 25px;
  margin-top: 4px;
`;

const Logout = styled.img`
  background-color: #2d73af;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-bottom: 20px;
`;
const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
const Item = styled.div`
  width: 100%;
  transition: 1s;
  padding:12px;
  &:hover {
    background: rgba(143, 196, 244, 0.68);
    }
  }
`;
const Image = styled.img`
  width: 100%;
  height: 30px;
  flex-shrink: 0;
`;
const Span = styled.span`
  color: white;
  font-size: 12px;
`;
