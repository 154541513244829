import React, { useState } from "react";
import { Modal, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import { styled } from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import TableCustom from "../../components/TableCustom";

import { changeStatus, getAllAccount, deleteAccount } from "../../api/account";

import { Checkbox } from "antd";
import ModalModifiedAccount from "../../components/ModalModifiedAccount";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AiFillCheckCircle } from "react-icons/ai";
import { VscError } from "react-icons/vsc";
import { FaRegCheckCircle } from "react-icons/fa";

const { confirm } = Modal;

const ConfigTable: React.FC = () => {
  const queryClient = useQueryClient();
  const Accounts = useQuery({ queryKey: ["Accounts"], queryFn: getAllAccount });

  const deleteMutate = useMutation({
    mutationFn: deleteAccount,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["Accounts"] });
      messageApi.open({
        type: "success",
        content: "Le compte a été supprimé avec succès.",
        duration: 2,
      });
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue lors de la suppréssion du compte",
        duration: 2,
      });
    },
  });

  // const statusMutate = useMutation({
  //   mutationFn: changeStatus,
  //   onSuccess: (data) => {
  //     queryClient.invalidateQueries({ queryKey: ["Accounts"] });
  //     messageApi.open({
  //       type: "success",
  //       content: data.actif
  //         ? `le compte ${data.nom} a été activé. `
  //         : `le compte  ${data.nom} a été désactivé`,
  //       duration: 2,
  //     });
  //   },
  //   onError: (err) => {
  //     messageApi.open({
  //       type: "error",
  //       content: "Une erreur est survenue lors de la mise à jour du compte",
  //       duration: 2,
  //     });
  //   },
  // });

  // declaration of states
  const [modified, SetModified] = useState(false);
  const [id, setID] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const accounts: DataType[] = [];

  // Loading data into account variables
  if (Accounts.data)
    for (let j = 0; j < Accounts.data.length; j++) {
      const compte = Accounts.data[j];
      accounts.push({
        key: compte.id,
        name: compte.nom,
        api: compte.api,
        email: compte.email,
        idOrganisation: compte.idOrganisation,
        cleSecret: compte.cleSecret,
        cleApi: compte.cleApi,
        update: new Date(compte.updatedDate).toLocaleString(),
        actif: compte.actif,
      });
    }

  // Setting up the table columns
  const columns: ColumnsType<DataType> = [
    {
      title: "Nom",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "Url",
      dataIndex: "api",
      align: "center",
    },
    {
      title: "Modifié le",
      dataIndex: "update",
      align: "center",
    },
    {
      title: "Actif",
      dataIndex: "actif",
      align: "center",
      render: (value, record: DataType) => {
        // const handleClick = () => {
        //   statusMutate.mutate({ idCompte: record.key, actif: !value });
        // };
        return (
          <div>
            {value ? (
              <FaRegCheckCircle color="green" size={20} />
            ) : (
              <VscError color="red" size={20} />
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        const handleClick = () => {
          setID(record.key);
          SetModified(true);
        };
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Span onClick={handleClick}>
              <BsFillPencilFill />
            </Span>
            <Span onClick={() => showDeleteConfirm(record)}>
              <BsFillTrashFill />
            </Span>
          </div>
        );
      },
    },
  ];

  const datas: DataType[] = accounts;
  // Modal that is displayed when deleting an account
  const showDeleteConfirm = (value: DataType) => {
    confirm({
      title: "Voulez vous vraiment supprimer cet élément ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Supprimer",
      okType: "danger",
      cancelText: "Annuler",
      centered: true,
      width: "420px",
      onOk() {
        deleteMutate.mutate(value.key.toString());
      },
      onCancel() {},
    });
  };

  return (
    <div>
      {contextHolder}
      {modified && (
        <ModalModifiedAccount visible={modified} change={SetModified} id={id} />
      )}
      <TableCustom
        columns={columns}
        dataSource={datas}
        pageSize={8}
        loading={Accounts.isLoading || deleteMutate.isPending}
      />
    </div>
  );
};

// Styles
const Span = styled.span`
  cursor: pointer;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: auto;
  padding: 2px;
  text-align: center;
  border-radius: 30px;
`;
// Interface
interface DataType {
  key: string;
  name: string;
  api: string;
  email: string;
  idOrganisation: string;
  cleSecret: string;
  cleApi: string;
  update: string;
  actif: boolean;
}
export default ConfigTable;
