import api from ".";
import { Report } from "../models/Report";

const getReportByUser = async () => {
  const res = await api.get("/report/by-me");
  const response: Report[] = JSON.parse(res.data);
  return response;
};

export { getReportByUser };
