import api from ".";
import {
  OrganisationResponse,
  AccountResponse,
} from "../interface/Organisation";

const getAllOrganisation = async () => {
  const res = await api.get("/organisation/by-me");
  const response: OrganisationResponse[] = JSON.parse(res.data);
  return response;
};
const invited = async (data: { idOrganisation: string; emails: string[] }) => {
  const res = await api.post("/organisation/send-invitation", data);
  const response = JSON.parse(res.data);
  return response;
};
const removeUsers = async (data: {
  emails: string[];
  idOrganisation: string;
}) => {
  const res = await api.post("/organisation/remove-users", data);
  const response = JSON.parse(res.data);
  return response;
};
const acceptInvitation = async (data: {
  token: string;
  nom: string;
  prenom: string;
  mdp: string;
}) => {
  const res = await api.post("/organisation/accept-invitation-new", data);
  const response: AccountResponse[] = JSON.parse(res.data);

  return response;
};
export { getAllOrganisation, acceptInvitation, invited, removeUsers };
