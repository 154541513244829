import api from ".";
import { TransactionResponse } from "../interface/transactions";

const getTransactionByConnector = async ({
  queryKey,
}: {
  queryKey: TransactionQueryKey;
}) => {
  const [_, idConnector] = queryKey;
  const res = await api.get("/transaction/by-config/" + idConnector);
  const response: TransactionResponse = JSON.parse(res.data);
  return response;
};

const remakeTransaction = async (idTransaction: string) => {
  const res = await api.post("/config-connecteur/tail/" + idTransaction);
  // console.log(idTransaction);
};

export { getTransactionByConnector, remakeTransaction };
type TransactionQueryKey = ["transactions", string];
