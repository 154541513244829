import api from "./";
import {
  ResponseAuthentification,
  UtilisateurLoginResponse,
} from "../interface/Utilisateur";

const login = async (data: { email: string; mdp: string }) => {
  const res = await api.post("/auth/login", data);
  const response: ResponseAuthentification = JSON.parse(res.data);
  localStorage.setItem("token", response.token);
  localStorage.setItem("user", JSON.stringify(response.utilisateur));
  return response;
};

const refresh = async () => {
  const res = await api.get("/utilisateur/me");
  const response: UtilisateurLoginResponse = JSON.parse(res.data);
  return response;
};

const changePassword = async (data: { mdp: string; nouveauMdp: string }) => {
  const res = await api.post("/utilisateur/change-password", data);
  const response = JSON.parse(res.data);
  return response;
};
const updateProfil = async (data: {
  idUser: string;
  nom: string;
  prenom: string;
  email: string;
}) => {
  const res = await api.patch("/utilisateur/" + data.idUser, {
    nom: data.nom,
    prenom: data.prenom,
    email: data.email,
  });
  const response = JSON.parse(res.data);

  return response;
};
const resetPassword = async (data: { email: string }) => {
  const res = await api.post("/utilisateur/reset-password", data);
  const response = JSON.parse(res.data);
  return response;
};
const logout = async () => {
  localStorage.clear();
};

export { login, refresh, logout, changePassword, updateProfil, resetPassword };
// ***************************************************************************************************************************************
