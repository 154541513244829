import React, { useState } from "react";
import { Input } from "antd";

interface CustomInputProps {
  type: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  width: number;
  required: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  type,
  onChange,
  placeholder,
  value,
  width,
  required,
}) => {
  const [content, setContent] = useState(value);

  return (
    <Input
      type={type}
      onChange={(e) => {
        setContent(e.target.value);
        onChange(e);
      }}
      size="large"
      style={{ height: 40, width, marginTop: 0 }}
      placeholder={placeholder}
      value={content}
      required={required}
    />
  );
};

export default CustomInput;
