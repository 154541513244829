import { keyframes, styled } from "styled-components";

// Center content on screen
export const CenterScreen = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
`;

const laodingCard = keyframes`
    0% {
        transform: translateX(0%);
        width: 100%;
    } 
    25%{
        transform: translateX(15%);
        width: 45%;
    }
    50%{
        transform: translateX(60%);
        width: 10%;
    }
    75%{
        transform: translateX(80%);
        width: 20%;
    }
    100% {
        width: 100%;
        transform: translateX(0%);
    } 
`;

// The outline map on user authentication forms
export const CardForm = styled.div<{ loading?: boolean }>`
  padding: 1em;
  height: 43vh;
  font-family: Poppins, sans-serif;
  width: 470px;
  border-radius: 3px;
  box-shadow: 0 0 4px 3px #a1a1a121;
  background-color: ${(props) => (props.loading ? "#ffffff2f" : "#fff")};
  position: relative;
  transition: all 0.3s ease;

  /* On ajoute un élément after */
  &::after {
    content: "";
    position: absolute;
    background-color: var(--primary);

    display: ${(props) => (props.loading ? "block" : "none")};
    z-index: 1;
    height: 4px;
    width: 10%;
    top: 0;
    left: 0;
    animation: ${laodingCard} 3s linear infinite;
  }

  /* Responsive card */
  @media screen and (max-width: 780px) {
    width: 90vw;
  }
`;

// Center container children
export const Center = styled.div<{ column?: boolean }>`
  width: 100%;
  height: ${(props) => (props.column ? "100%" : "auto")};
  display: flex;
  justify-content: ${(props) => (props.column ? "space-around" : "center")};
  align-items: center;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
`;

// Horizontal line with children at the beginning
export const Row = styled.div<{ alignStart?: boolean; width?: string }>`
  width: ${(props) => (props.width ? props.width : "auto")};
  display: flex;
  justify-content: ${(props) => (props.alignStart ? "flex-start" : "flex-end")};
  align-items: center;
  flex-direction: row;
`;

// The vertical column
export const Col = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
