import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import TableCustom from "../../components/TableCustom";
import type { ColumnsType } from "antd/es/table";
import { FcInfo } from "react-icons/fc";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import ModalDrive from "../../components/ModalDrive";
import GDR from "../../assets/Google_Drive_icon.svg";
import DRP from "../../assets/Dropbox_Icon.svg";
import OND from "../../assets/Microsoft_Office_OneDrive_(2019–present).svg";
import SRP from "../../assets/microsoft-sharepoint.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthenticationResult } from "@azure/msal-browser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteDrive,
  getAllDrive,
  getAllDriveCategorie,
  DriveObject,
} from "../../api/drive";
import { getAllOrganisation } from "../../api/organisation";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, message } from "antd";
import ModalModifiedDrive from "../../components/ModalModifiedDrive";

export default function Organisation() {
  const { confirm } = Modal;
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, setModal] = useState<boolean>(false);
  const [modified, setModified] = useState<boolean>(false);
  const [id, setID] = useState<string>("");
  const CategorieDrive = useQuery({
    queryKey: ["CategorieDrive"],
    queryFn: getAllDriveCategorie,
  });

  const Organisations = useQuery({
    queryKey: ["Organisations"],
    queryFn: getAllOrganisation,
    enabled: CategorieDrive.isSuccess,
  });

  const getDrives = useQuery({
    queryKey: ["drivesAccount"],
    queryFn: getAllDrive,
    enabled: Organisations.isSuccess,
  });
  const deleteDriveMutate = useMutation({
    mutationFn: deleteDrive,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["drivesAccount"] });
      messageApi.open({
        type: "success",
        content: "Le compte a été supprimé acec succès",
        duration: 2,
      });
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue lors de la suppéssion du compte",
        duration: 2,
      });
    },
  });

  const columns: ColumnsType<DriveObject[]> = [
    {
      title: "Nom",
      dataIndex: "nom",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Fournisseur",
      dataIndex: "idCategorieDrive",
      align: "center",
      render: (text, render) => {
        // console.log(render);
        let icon: string = "";
        switch (text) {
          case "SRP":
            icon = SRP;
            break;

          default:
            icon = SRP;
        }

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {icon ? <img src={icon} width={20} /> : ""}
          </div>
        );
      },
    },
    {
      title: "Date de création",
      dataIndex: "createdDate",
      ellipsis: true,
      align: "center",
      render: (text) => {
        return <div>{new Date(text).toLocaleString()}</div>;
      },
    },
    {
      title: "Organisation",
      dataIndex: "idOrganisation",
      align: "center",
      render: (text) => {
        let name = "";
        if (text && Organisations.data) {
          name = Organisations.data.find((item) => item.id === text)?.nom || "";
        }
        return <div>{name}</div>;
      },
    },
    {
      title: "Actions",
      width: 90,
      dataIndex: "id",
      align: "center",
      render: (text) => {
        const handleClick = () => {
          setID(text);
          setModified(true);
        };
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Span onClick={() => handleClick()}>
              <BsFillPencilFill />
            </Span>
            <Span onClick={() => showDeleteConfirm(text)}>
              <BsFillTrashFill />
            </Span>
          </div>
        );
      },
    },
  ];
  const showDeleteConfirm = (value: string) => {
    confirm({
      title: "Voulez vous vraiment supprimer ce compte ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Supprimer",
      okType: "danger",
      cancelText: "Annuler",
      centered: true,
      width: "420px",
      onOk() {
        deleteDriveMutate.mutate({ idDrive: value });
      },
      onCancel() {},
    });
  };
  return (
    <>
      {contextHolder}
      <>
        {modal && <ModalDrive visible={modal} change={setModal} />}
        {modified && (
          <ModalModifiedDrive visible={modified} change={setModified} id={id} />
        )}
      </>
      <Header isDrive={true} />
      <Container>
        <SubContainer>
          <Title>Liste de mes drives </Title>
          <Button onClick={() => setModal(true)}>Ajouter un drive</Button>
        </SubContainer>
        <TableCustom
          columns={columns}
          dataSource={getDrives.data || []}
          pageSize={15}
          loading={getDrives.isLoading || Organisations.isLoading}
        />
      </Container>
    </>
  );
}
const Span = styled.span`
  cursor: pointer;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: auto;
  padding: 2px;
  text-align: center;
  border-radius: 30px;
`;
const Container = styled.div`
  background: rgb(255, 255, 255);
  padding: 10px;
  height: 90vh;
`;

const SubContainer = styled.div`
  background: #4e8ccb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px;
`;

const Title = styled.p`
  margin-left: 12px;
  font-size: 1.45rem;
`;

interface selected {
  label: string;
  value: string;
}
interface DataType {
  key: number;
  titre: string;

  organisation: boolean;
  code: number;
  createdDate: Date;
}
const Button = styled.button`
  width: 150px;
  height: 40px;
  align-items: center;
  background: #4e8ccb;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: 16px;
  padding: 0 2px;
  color: white;
  border: 1px solid white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 20px;
  transition: 1s;
  fontweight: bold;
  fontsize: 1.4em;
  &:hover {
    background: #4e8ddf;
    cursor: pointer;
  }
`;
