import { default as axios } from "axios";

export const HOST = "https://api-connect.daxium-air.com";
export const URLREDIRECTIONMICROSOFT = "https://connect.daxium-air.com";

const api = axios.create({
  baseURL: HOST,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: [
    (data, headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      return JSON.stringify(data);
    },
  ],
  transformResponse: [],
});

export default api;
