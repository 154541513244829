import React, { useState, useEffect } from "react";
import { Table, Modal, Divider, Select, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { FcInfo } from "react-icons/fc";
import { VscSync, VscWorkspaceUntrusted } from "react-icons/vsc";
import { AiOutlineCheck } from "react-icons/ai";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { orderBy } from "lodash";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getConfigByConnecteur } from "../../api/connector-config";
import {
  getTransactionByConnector,
  remakeTransaction,
} from "../../api/transactions";
import { TransactionResponse } from "../../interface/transactions";
import { BiSolidCopyAlt } from "react-icons/bi";
import { VscDebugStart } from "react-icons/vsc";

const TableConnectorTransaction: React.FC = () => {
  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const configs = useQuery({
    queryKey: ["configConnector", id || ""],
    queryFn: getConfigByConnecteur,
    enabled: Boolean(id),
  });

  const startMutate = useMutation({
    mutationFn: remakeTransaction,
    onSuccess: () => {
      messageApi.open({
        type: "success",
        content: `la transaction a été relancée avec succès`,
        duration: 2,
      });
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: `Echec, la transaction n'a pas pu être relancée `,
        duration: 2,
      });
    },
  });

  const formattedConfig: { label: string; value: string; key: string }[] = [];
  if (configs.data) {
    for (let i = 0; i < configs.data.length; i++) {
      const element = configs.data[i];
      formattedConfig.push({
        label: element.nom,
        value: element.id,
        key: element.id,
      });
    }
  }
  const [idConfig, setIdConfig] = useState<string>("");
  useEffect(() => {
    if (formattedConfig.length > 0) {
      setIdConfig(formattedConfig[0]?.value);
    }
  }, [configs.isSuccess]);

  const getTransaction = useQuery({
    queryKey: ["transactions", idConfig || ""],
    queryFn: getTransactionByConnector,
    enabled: Boolean(idConfig),
  });
  let transactions: any[] = [];
  if (getTransaction.data) {
    transactions = getTransaction.data.transactions;
  }

  const data: DataType[] = orderBy(transactions, ["createdDate"], ["desc"]).map(
    (item, index) => {
      return {
        key: item.id,
        idTransaction: item.id,
        intitule:
          formattedConfig.find((item) => item.value === idConfig)?.label || "",
        counter: item.counter,
        message: item.message,
        erreur: item.error,
        creation: new Date(item.createdDate).toLocaleString(),
        update: new Date(item.updatedDate).toLocaleString(),
        status: item.status,
        submissionId: item.submissionId,
        transactionChamps: item.transactionChamps,
        updatedData: JSON.parse(item?.updatedData),
      };
    }
  );
  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(id);
    messageApi.open({
      type: "success",
      content: `l'id ${id} de la transaction a été bien copié`,
      duration: 2,
    });
  };
  const handleCopySbmission = (id: string) => {
    navigator.clipboard.writeText(id);
    messageApi.open({
      type: "success",
      content: `l'id ${id} de la submission a été bien copié`,
      duration: 2,
    });
  };

  const info = (transaction: DataType) => {
    Modal.info({
      title: ``,
      centered: true,
      maskClosable: true,
      keyboard: true,
      content: (
        <div style={{ margin: 0 }}>
          <p style={{ textAlign: "right" }}>
            <BiSolidCopyAlt
              onClick={() => handleCopy(transaction.idTransaction)}
              style={{ cursor: "pointer" }}
            />
          </p>
          <p>Intitulé : {transaction.intitule} </p>
          <p>Date de création : {transaction.creation} </p>
          <p>Date de mise à jour : {transaction.update} </p>
          {transaction.submissionId && (
            <>
              <p>submissionId : {transaction.submissionId} </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Copier l'id de la fiche
                <BiSolidCopyAlt
                  onClick={() =>
                    handleCopySbmission(transaction?.submissionId || "")
                  }
                  style={{ cursor: "pointer" }}
                />
              </div>
            </>
          )}
          <p>Info : {transaction.message} </p>
          <p>Erreur : {transaction.erreur} </p>
          <p>Counter : {transaction.counter} </p>
          <Divider />

          {transaction.transactionChamps.map((item: any) => {
            return (
              <div
                style={{
                  width: 300,
                }}
              >
                <p>Nom : {item.champ.libelle} </p>
                <p>Valeur : {item.value}</p> <Divider />
              </div>
            );
          })}

          {transaction.updatedData && (
            <>
              <p
                style={{
                  textAlign: "center",
                  marginRight: 10,
                  fontSize: 25,
                  marginBottom: 10,
                }}
              >
                Les données mise à jour
              </p>

              {transaction.updatedData &&
                transaction.updatedData?.map((item: any) => {
                  return (
                    <div>
                      <p>Champ : {item.name} </p>
                      <p>Valeur : {JSON.stringify(item.value)}</p> <Divider />
                    </div>
                  );
                })}
            </>
          )}
        </div>
      ),
      onOk() {},
      onCancel() {},
    });
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Date de création",
      dataIndex: "creation",
      width: "16%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Date de mise à jour",
      dataIndex: "update",
      width: "16%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Message",
      dataIndex: "message",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Jeton(s)",
      dataIndex: "counter",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Statut",
      dataIndex: "status",
      width: "8%",
      align: "center",
      render: (text, record) => {
        return (
          <div key={record.idTransaction}>
            {record.status === "wait" && <VscSync />}
            {record.status === "ok" && <AiOutlineCheck />}
            {record.status === "ko" && <VscWorkspaceUntrusted />}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: "5%",
      dataIndex: "message",
      align: "center",
      render: (text, record) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
            key={record.idTransaction}
          >
            <div onClick={() => info(record)} style={{ cursor: "pointer" }}>
              <FcInfo />
            </div>
            {record.submissionId && (
              <div
                onClick={() => {
                  startMutate.mutate(record.idTransaction);
                }}
                style={{ cursor: "pointer" }}
              >
                <VscDebugStart style={{ cursor: "pointer" }} />
              </div>
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Actions",
    //   width: "5%",
    //   dataIndex: "message",
    //   align: "center",
    //   render: (text, record) => {
    //     return (
    //       <VscDebugStart
    //         style={{ cursor: "pointer" }}
    //         // onClick={() => {
    //         //   startMutate.mutate(record.id);
    //         // }}
    //       />
    //     );
    //   },
    // },
  ];

  return (
    <div>
      {contextHolder}
      <div
        style={{
          // border: "1px solid black",
          display: "flex",
          alignItems: "center",
          paddingBottom: 20,
        }}
      >
        <Title style={{ marginRight: 20 }}>TRANSACTIONS : </Title>
        <Select
          showSearch={true}
          optionFilterProp="label"
          // disabled={!Applications.isSuccess}
          style={{ width: 250, margin: 0, padding: 0 }}
          size="middle"
          loading={configs.isLoading}
          value={idConfig}
          placeholder="Choisissez la configuration"
          onChange={(val) => {
            setIdConfig(val);
          }}
          options={formattedConfig}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        size="small"
        pagination={{ pageSize: 3 }}
        loading={getTransaction.isLoading}
        locale={{
          emptyText: "Cette configuration ne possède aucune transaction.",
        }}
      />
    </div>
  );
};

export default TableConnectorTransaction;

interface DataType {
  key: string;
  idTransaction: string;
  intitule: string;
  creation: string;
  counter: number;
  update: string;
  message: string | null;
  submissionId: string | null;
  erreur?: string | null;
  status: string;
  transactionChamps: any;
  updatedData: any[];
}
const Title = styled.p`
  text-align: left;
  font-size: 1rem;
`;
