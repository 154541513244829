import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import ModalAccount from "../../components/ModalAccount";
import TableCustom from "./ConfigTable";

export default function Account() {
  const [showAddAccount, SetshowAddAccount] = useState(false);
  return (
    <>
      {showAddAccount && (
        <ModalAccount visible={showAddAccount} change={SetshowAddAccount} />
      )}
      <Header isAccount={true} />
      <Container>
        <SubContainer>
          <Title>Liste de mes comptes associés à Daxium-Air</Title>
          <Button onClick={() => SetshowAddAccount(true)}>
            Ajouter un compte
          </Button>
        </SubContainer>
        <TableCustom />
      </Container>
    </>
  );
}

// Styles
const Container = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 6px;
  padding: 10px;
  height: 90vh;
`;
const Button = styled.button`
  width: 150px;
  height: 40px;
  align-items: center;
  background: #4e8ccb;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: 16px;
  padding: 0 2px;
  color: white;
  border: 1px solid white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 20px;
  transition: 1s;
  fontweight: bold;
  fontsize: 1.4em;
  &:hover {
    background: #4e8ddf;
    cursor: pointer;
  }
`;
const SubContainer = styled.div`
  background: #4e8ccb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px;
`;
const Title = styled.p`
  margin-left: 12px;
  font-size: 1.45rem;
`;
