import React, { useState } from "react";
import CustomInput from "./CustomInput";
import { ConnectorConfigInputProps } from "../models/components-props.model";
import styled from "styled-components";
import { Checkbox, Space, Switch, Select, Tooltip } from "antd";
import SelectCustom from "./SelectCustom";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { isArray } from "lodash";
import { InfoCircleOutlined, RollbackOutlined } from "@ant-design/icons";

const InputField: React.FC<ConnectorConfigInputProps> = ({
  id,
  required,
  placeholder,
  libelle,
  value,
  setValue,
  aide,
  type,
  fields,
  isConcat,
  isCallback,
  enfants = [],
  options = [],
}) => {
  const [isFile, setIsFile] = useState(true);
  const opt = options.map((item: any) => {
    return { label: item?.label, value: String(item?.value) };
  });
  // console.log({ value });
  return (
    <ContainerInput
      style={{
        flexDirection: type === "5" ? "row" : "column",
        alignItems: type === "5" ? "end" : "",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {type !== "6" && (
        <Title
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // border: "1px solid black",
            width: 400,
          }}
        >
          <p>
            {libelle}
            {isCallback && (
              <span>
                <RollbackOutlined />
              </span>
            )}{" "}
            {required && <span style={{ color: "red" }}>*</span>}
          </p>
          {aide && (
            <Tooltip
              title={() => <p style={{ color: "#444" }}> {aide} </p>}
              color={"white"}
            >
              <InfoCircleOutlined
                style={{ color: "#444", cursor: "pointer" }}
              />
            </Tooltip>
          )}
        </Title>
      )}
      {["1", "8"].includes(type) && (
        <SelectCustom
          type={type}
          fields={fields}
          isCallback={isCallback}
          isConcat={isConcat}
          onChange={(val: string) => {
            setValue({ ...value, [id]: val });
          }}
          value={value[id] || ""}
          placeholder={placeholder}
          required={required}
        />
      )}
      {["2", "3"].includes(type) && (
        <CustomInput
          type={2 ? "number" : "date"}
          onChange={(e) => setValue({ ...value, [id]: e.target.value })}
          value={value[id] || ""}
          placeholder={placeholder}
          width={400}
          required={required}
        />
      )}
      {type === "4" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomInput
            type={isFile ? "file" : "url"}
            onChange={(e) => setValue({ ...value, [id]: e.target.value })}
            value={value[id] || ""}
            placeholder={placeholder}
            width={300}
            required={required}
          />
          <Switch
            checkedChildren="Fichier"
            unCheckedChildren="Url"
            defaultChecked
            onChange={setIsFile}
            style={{ marginLeft: 20 }}
          />
        </div>
      )}
      {type === "5" && (
        <Checkbox
          value={value[id] || false}
          onChange={(e: CheckboxChangeEvent) =>
            setValue({ ...value, [id]: e.target.checked })
          }
        />
      )}
      {type === "7" && isArray(options) && options.length > 1 && (
        <Select
          placeholder={placeholder}
          size="large"
          style={{ height: 40, width: 400 }}
          showSearch={true}
          optionFilterProp="label"
          value={value[id] || ""}
          onChange={(val: any) => {
            setValue({ ...value, [id]: val });
          }}
          options={opt || []}
        />
      )}{" "}
      {type === "7" && isArray(options) && options.length === 0 && (
        <SelectCustom
          isCallback={isCallback}
          isConcat={isConcat}
          fields={fields}
          onChange={(val: string) => setValue({ ...value, [id]: val })}
          value={value[id] || ""}
          placeholder={placeholder}
          required={required}
        />
      )}
    </ContainerInput>
  );
};
const ContainerInput = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  gap: inherit;
`;
const Title = styled.p`
  font-size: 1.02rem;
  margin-bottom: 0;
  font-weight: 650;
`;
export default InputField;
