import React, { useState } from "react";
import { useAppDispatch } from "../redux/store";
import styled from "styled-components";
import notif from "../assets/notif.png";

import { useNavigate } from "react-router-dom";

import type { MenuProps } from "antd";
import { Menu } from "antd";
import { logout, refresh } from "../api/auth";
import { useQuery } from "@tanstack/react-query";

type MenuItem = Required<MenuProps>["items"][number];

const Header: React.FC<propsHeader> = ({
  isHome,
  isConfig,
  isAccount,
  isProfil,
  isOrganisation,
  isDrive,
  isConsumption,
}) => {
  const [current, setCurrent] = useState("");
  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: refresh,
    // enabled: mutate.isSuccess,
  });
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth");
  };

  const items: MenuItem[] = [
    getItem(
      null,
      "sub2",
      <UserContainer>
        <Avatar />
        <TitleContainer>
          <Name>{`${userInfo.data?.prenom} ${userInfo.data?.nom}`}</Name>
          <Info>Daxium</Info>
        </TitleContainer>
      </UserContainer>,
      [
        getItem(<span key={"12"}>Mon Compte</span>, "/profil"),
        getItem(<span onClick={handleLogout}>se déconnecter</span>, "12"),
      ]
    ),
  ];
  const onSelect = (val: any) => {
    if (val?.key === "12") {
      handleLogout();
    } else {
      setCurrent(val?.key);
      navigate(val?.key);
    }
  };
  return (
    <Container>
      {isHome && (
        <TitleContainer>
          <Title>Dashboard</Title>
          <Info>Tous vos connecteurs, dans un seul et même endroit.</Info>
        </TitleContainer>
      )}
      {isOrganisation && (
        <TitleContainer>
          <Title>Gestion de mes Organisations</Title>
        </TitleContainer>
      )}
      {isConfig && (
        <TitleContainer>
          <Title>Configurer un connecteur</Title>
        </TitleContainer>
      )}
      {isAccount && (
        <TitleContainer>
          <Title>Gestion de mes comptes</Title>
        </TitleContainer>
      )}
      {isProfil && (
        <TitleContainer>
          <Title>Mon Profil</Title>
        </TitleContainer>
      )}
      {isDrive && (
        <TitleContainer>
          <Title>Mon Drive</Title>
        </TitleContainer>
      )}
      {isConsumption && (
        <TitleContainer>
          <Title>Mes Consommations</Title>
        </TitleContainer>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Notification src={notif} alt="notification" />
        <Menu
          style={{
            background: "#ecf0f4",
            minWidth: 280,
            width: 280,
            border: "none",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          // openKeys={["sub1"]}
          selectedKeys={[current]}
          mode="horizontal"
          // inlineCollapsed={false}
          items={items}
          triggerSubMenuAction={"hover"}
          onSelect={onSelect}
        />
      </div>
    </Container>
  );
};
export default Header;

interface propsHeader {
  isHome?: boolean;
  isConfig?: boolean;
  isAccount?: boolean;
  isProfil?: boolean;
  isOrganisation?: boolean;
  isDrive?: boolean;
  isConsumption?: boolean;
}
function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  theme?: "light" | "dark"
  // popupClassName
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    theme,
  } as MenuItem;
}
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Notification = styled.img`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 3px;
  border-radius: 50px;
  justify-content: center;
  background-color: #ffffff;
  margin: 0px 10px 0px 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Avatar = styled.span`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: #4e8ccb;
  border-radius: 50px;
  margin-right: 13px;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.span`
  color: #00000c;
  font-size: 28px;
  font-style: normal;
  font-size: 1.8rem;
`;

const Name = styled.span`
  color: #273c4e;
  font-size: 15px;
  font-weight: 700;
`;

const Info = styled.span`
  ccolor: #3e5366;
  font-size: 0.72rem;
  font-weight: 400;

  top: 43px;

  // left: 0;
`;
