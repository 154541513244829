import React, { useEffect, useState } from "react";
import { Select, Space, ConfigProvider, Checkbox } from "antd";
import styled from "styled-components";
import { Modal, Button, TimePicker, Input } from "antd";
import dayjs from "dayjs";
interface cronData {
  heure: number;
  minute: number;
  coef: number;
  frequence: number;
  jour: number;
  dernierJour: boolean;
  mois: number;
  dim: boolean;
  lun: boolean;
  mar: boolean;
  mer: boolean;
  jeu: boolean;
  ven: boolean;
  sam: boolean;
}
interface periodiciteProps {
  data: cronData | null;
  change: Function;
  update?: boolean;
  disabled: boolean;
}

const Periodicite: React.FC<periodiciteProps> = ({
  data,
  change,
  update,
  disabled,
}) => {
  const [periode, setPeriode] = useState(0);
  const [frequence, setFrequence] = useState(1);
  const [open, setOpen] = useState(false);
  const [joursDeLaSemaine, setJoursDeLaSemaine] = useState("Lundi");
  const [months, setMonths] = useState(1);
  const [lastDay, setLastDay] = useState(false);
  const [days, setDays] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [shareTime, setShareTime] = useState<any>();
  const [jour, setJour] = useState(1);
  const jourDeLaSemaine = [
    "Lundi ",
    "Mardi ",
    "Mercredi ",
    "Jeudi ",
    "Vendredi ",
    "Samedi ",
    "Dimanche ",
  ];

  const handleOk = () => {
    updateCron();
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onChangeTime = (e: any) => {
    setShareTime(e);
    const heure = e["$H"];
    const minute = e["$m"];
    change({ ...data, heure, minute });
  };
  const handleSubmit = () => {
    updateCron();
    handleCancel();
  };
  const updateCron = () => {
    if (periode !== 0) {
      const heure = data?.heure || 0;
      const minute = data?.minute || 0;
      change({
        ...data,
        coef: periode,
        jour,
        frequence: frequence <= 0 ? 1 : frequence,
        mois: months,
        lun: days[0],
        mar: days[1],
        mer: days[2],
        jeu: days[3],
        ven: days[4],
        sam: days[5],
        dim: days[6],
        dernierJour: lastDay,
        heure,
        minute,
      });
    } else {
      change(null);
    }
  };
  useEffect(() => {
    if (data && update) {
      setPeriode(data.coef);
      setJour(data.jour);
      setFrequence(data.frequence);
      setMonths(data.mois);
      setDays([
        data.lun,
        data.mar,
        data.mer,
        data.jeu,
        data.ven,
        data.sam,
        data.dim,
      ]);
      setLastDay(data.dernierJour);
      const maDate = dayjs(`${data?.heure || 0}:${data?.minute || 0}`, "HH:mm");

      setShareTime(maDate);
    }
  }, [data, update]);

  // console.log(data);
  return (
    <>
      {open && (
        <Modal
          style={{
            top: 10,
            minWidth: 500,
          }}
          title=""
          width={640}
          centered={true}
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                key="back"
                size={"large"}
                onClick={handleCancel}
                style={{
                  fontSize: "1rem",
                  padding: "5px",
                  textAlign: "center",
                  width: "120px",
                }}
              >
                ANNULER
              </Button>
              <Button
                key="submit"
                size={"large"}
                onClick={handleSubmit}
                style={{
                  background: "#4e8ccb",
                  color: "white",
                  fontSize: "1rem",
                  paddingLeft: "13px",
                  paddingRight: "13px",
                  textAlign: "center",
                  width: "auto",
                }}
              >
                ENREGISTRER
              </Button>
            </div>,
          ]}
        >
          <Content>Définir une periodicité</Content>
          <Container>
            <Label>
              <Title>Répétez tous les</Title>
            </Label>
            <Cont>
              <Input
                type="number"
                style={{ width: 75, height: 40, textAlign: "center" }}
                value={frequence}
                min={0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let value = Number(e.target.value);

                  setFrequence(value);
                }}
                disabled={periode === 4}
              />
              <Select
                showSearch={true}
                optionFilterProp="label"
                placeholder="Periode"
                style={{ width: 190, margin: 0, padding: 0 }}
                size="large"
                value={periode}
                onSelect={(value) => {
                  if (value === 4) {
                    setFrequence(1);
                  }
                  setPeriode(value);
                }}
                options={[
                  { value: 1, label: "Jour" },
                  { value: 2, label: "Semaine" },
                  { value: 3, label: "Mois" },
                  { value: 4, label: "Année" },
                ]}
              />{" "}
            </Cont>
          </Container>

          {/* Definir la periode en fonction de la semaine */}
          {periode === 2 && (
            <Container>
              <Label>
                <Title>Liste des jours </Title>
              </Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  alignItems: "center",
                  width: 360,
                }}
              >
                {jourDeLaSemaine.map((item, index) => {
                  return (
                    <ConfigProvider
                      theme={{
                        components: {
                          Button: {
                            colorPrimary: "#2d73af",
                          },
                        },
                      }}
                    >
                      <Button
                        key={index}
                        type={days[index] ? "primary" : "default"}
                        onClick={() => {
                          const tab = days;
                          tab[index] = !tab[index];
                          setDays([...tab]);
                          setJoursDeLaSemaine(
                            jourDeLaSemaine
                              .filter((j, index) => days[index])
                              .toString()
                          );
                        }}
                      >
                        {item[0]}
                      </Button>
                    </ConfigProvider>
                  );
                })}
              </div>
            </Container>
          )}

          {/* Si la periode est un mois ou une année */}
          {[3, 4].includes(periode) && (
            <Container>
              <Label>
                <Title>Le</Title>
              </Label>
              <Cont>
                <Input
                  type="number"
                  style={{
                    width: 75,
                    height: 40,
                    textAlign: "center",
                  }}
                  min={0}
                  disabled={lastDay}
                  value={jour}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let value = Number(e.target.value);
                    if (value > 31) value = 31;
                    // else if (value <= 0) value = 1;
                    setJour(value);
                  }}
                />
                {periode === 3 && (
                  <Checkbox
                    onChange={(e) => setLastDay(e.target.checked)}
                    checked={lastDay}
                  >
                    Tous les jours
                  </Checkbox>
                )}
                {/* Si la periode est une année */}
                {periode === 4 && (
                  <Select
                    showSearch={true}
                    optionFilterProp="label"
                    placeholder="Mois"
                    style={{ width: 190, marginLeft: 0, padding: 0 }}
                    size="large"
                    value={months}
                    onSelect={(val) => {
                      setMonths(val);
                    }}
                    options={[
                      { value: 1, label: "Janvier" },
                      { value: 2, label: "Février" },
                      { value: 3, label: "Mars" },
                      { value: 4, label: "Avril" },
                      { value: 5, label: "Mai" },
                      { value: 6, label: "Juin" },
                      { value: 7, label: "Juillet" },
                      { value: 8, label: "Août" },
                      { value: 9, label: "Septembre" },
                      { value: 10, label: "Octobre" },
                      { value: 11, label: "Novembre" },
                      { value: 12, label: "Décembre" },
                    ]}
                  />
                )}
              </Cont>
            </Container>
          )}
        </Modal>
      )}
      <ContainerInput direction="horizontal">
        <Title> Periodicité :</Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "space-between",
          }}
        >
          <Select
            disabled={disabled}
            showSearch={true}
            optionFilterProp="label"
            placeholder=""
            style={{ width: 320, marginRight: 20, padding: 0 }}
            size="large"
            value={periode}
            onSelect={(val) => {
              if (val === 0) change(null);
              setPeriode(Number(val));
              if (Number(val) !== 0) {
                setOpen(true);
              }
            }}
            options={[
              { value: 0, label: "Ne se repète pas" },
              { value: 1, label: "Journalière" },
              { value: 2, label: "Hebdomadaire" },
              { value: 3, label: "Mensuel" },
              { value: 4, label: "Annuelle" },
            ]}
          />
          <TimePicker
            onSelect={onChangeTime}
            placeholder="Heure"
            value={shareTime}
            style={{ height: 40, borderRadius: 5 }}
            format={"HH:mm"}
            disabled={disabled || periode === 0}
            showNow={false}
            inputReadOnly={true}
            allowClear={false}
            onChange={onChangeTime}
          />
        </div>
      </ContainerInput>
    </>
  );
};

export default Periodicite;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
`;
const Cont = styled.div`
  width: 60%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
`;
const Title = styled.p`
  font-size: 1.02rem;
  padding-top: 0;
  align-items: right;
  // font-weight: 650;
  // margin-top: 18px;
  padding-bottom: 10px;
`;
const ContainerInput = styled(Space)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`;
const Content = styled.h1`
  color: #444;
  font-size: 1.2rem;
  padding: 3px;
  width: 100%;
`;
const Label = styled.div`
  width: 30%;
  text-align: right;
`;
