import React, { useContext, useState, useEffect, useRef } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Table, Button, Form, message, Select, Popover } from "antd";
import {
  AiOutlineCheck,
  AiOutlineExclamation,
  AiOutlineWarning,
} from "react-icons/ai";

interface DataObject {
  key: number;
  name: string;
  type: string;
  comment: string;
}

interface TableEditableProps {
  updateData?: (data: any[]) => void;
  itemsName: { nom: string; type: string }[];
  type?: string;
  data?: any[];
  fieldsDaxium: any[];
  sheet?: string;
  dataSource: any[];
  setDataSource: Function;
  loading: boolean;
}

const EditableContext = React.createContext<any>(null);

const EditableRow: React.FC<any> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<any> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  itemsName,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form?.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      if (form) {
        let values = await form.validateFields();

        const type = itemsName.find((t: any) => t.nom === values.nom).type;

        toggleEdit();
        handleSave({ ...record, ...values, type });
      }
    } catch (errInfo) {
      message.error(
        "Une erreur a été détectée lors de l'insertion des données"
      );
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[]}
      >
        <Select
          showSearch={true}
          optionFilterProp="label"
          ref={inputRef}
          onSelect={save}
          placeholder={`Entrer la ${
            title.toLowerCase() === "key" ? "clé" : "valeur"
          }`}
          onBlur={save}
          options={itemsName.map((item: any, index: number) => {
            return { label: item.nom, value: item.nom, key: index };
          })}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          color: children[1] ? "initial" : "#00000060",
        }}
        onClick={toggleEdit}
      >
        {children[1]
          ? children
          : [
              undefined,
              `Entrer la ${title.toLowerCase() === "key" ? "clé" : "valeur"}`,
            ]}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TableEditable: React.FC<TableEditableProps> = ({
  updateData,
  type,
  itemsName,
  dataSource,
  setDataSource,
  fieldsDaxium,
  loading,
}) => {
  const handleDelete = (key: number) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const columns: any = [
    {
      title: "Nom",
      dataIndex: "nom",
      editable: true,
      width: "30%",
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: false,
      width: "15%",
    },
    {
      title: "Champ Daxium",
      dataIndex: "select",
      width: "40%",
      render: (_: any, record: any) => (
        <Select
          showSearch={true}
          optionFilterProp="label"
          disabled={fieldsDaxium?.length === 0}
          style={{
            width: "100%",
            marginLeft: 0,
            padding: 0,
            height: 40,
          }}
          size="large"
          onChange={(value) => {
            const data = dataSource?.map((item) => {
              if (item?.key === record?.key) {
                item.formDaxium = value;
              }
              return item;
            });
            setDataSource(data);
          }}
          value={record?.formDaxium}
          options={fieldsDaxium
            // ?.filter((item: any) => {
            //   const type = record.type === "string" ? "text" : record.type;
            //   // console.log(record.type === item.type, item.type);
            //   return record.type === item.type;
            // })
            ?.map((item: any) => {
              return { label: item?.label, value: item?.name };
            })}
        />
      ),
    },
    {
      title: "",
      dataIndex: "status",
      editable: false,
      // center: true,
      width: "10%",
      render: (_: any, record: any) => {
        const type =
          fieldsDaxium.find((item: any) => item.name === record.formDaxium)
            ?.type || "";

        return (
          <div style={{ textAlign: "center", cursor: "pointer" }}>
            {type ? (
              type === record.type ? (
                <Popover
                  content={<div>les deux champs sont du même type.</div>}
                  trigger="hover"
                >
                  <AiOutlineCheck color="green" size={20} />
                </Popover>
              ) : (
                <Popover
                  content={
                    <div>
                      Le champ daxium est de type <strong>{type}</strong> et la
                      colonne de type <strong>{record.type}</strong>.
                    </div>
                  }
                  trigger="hover"
                >
                  <AiOutlineWarning color="orange" size={20} />
                </Popover>
              )
            ) : (
              <Popover
                content={
                  <div>Vous n'avez pas sélectionné de champ daxium.</div>
                }
                trigger="hover"
              >
                <AiOutlineExclamation color="red" size={20} />
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "sup",
      width: "10%",
      editable: false,
      render: (_: any, record: any) =>
        dataSource.length >= 1 ? (
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.key)}
          />
        ) : null,
    },
  ];

  const addItem = () => {
    const newparam: DataObject = {
      key: dataSource?.length,
      name: "",
      type: "",
      comment: "",
    };

    const newData = [...dataSource, newparam];
    setDataSource(newData);
  };

  const handleSave = (row: DataObject) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const Ncolumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: DataObject) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        itemsName: itemsName,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <div>
      <Table
        scroll={{ x: 200 }}
        components={components}
        rowClassName={() => "editable-row"}
        pagination={false}
        dataSource={dataSource}
        columns={Ncolumns}
        locale={{ emptyText: "Aucune donnée à Afficher" }}
        size="small"
        style={{ width: "100%" }}
        loading={loading}
      />
      <div style={{ textAlign: "right", marginTop: "5px" }}>
        <Button onClick={addItem}>Ajouter</Button>
      </div>
    </div>
  );
};

export default TableEditable;
