import { Checkbox, Modal } from "antd";
import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Space,
  Select,
  Divider,
  ConfigProvider,
  Switch,
} from "antd";
import styled from "styled-components";

import { useParams } from "react-router-dom";
import {
  getApplication,
  getStructure,
  createConfig,
  getReport,
} from "../api/connector-config";
import { ConnectorConfigInputState } from "../models/connector-config.model";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import InputField from "./InputField";
import { message, Upload, Radio } from "antd";
import Periodicite from "./Periodicite";
import TableEditable from "./TableEditable";
import type { UploadFile } from "antd/es/upload/interface";
import { deletedFile } from "../api/file";
import { orderBy } from "lodash";
import { getConfigMappingFile, getConfigMappingUrl } from "../api/mapping";
import InputObjet from "./InputObjet";
import { HOST } from "../api/index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Champ } from "../models/connector.model";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { getOneConnector } from "../api/connector";
import { getAllAccount } from "../api/account";
import ModalFileDrive from "./ModalFolderDrive";
import { getAllDrive, getFilesDrive, getDriveNodeF } from "../api/drive";
import { FileItem } from "../interface/drive";
import { CustomDataNode } from "./ModalFolderDrive";

const { Dragger } = Upload;
const { Search } = Input;

const ModalAddConfiguration: React.FC<data> = ({ visible, change }) => {
  const queryClient = useQueryClient();
  const { confirm } = Modal;
  const { id } = useParams();

  // Basic connector configuration field
  const [name, setName] = useState<string>("");
  const [selectedForm, setSelectedForm] = useState<string>("");
  const [selectedAccount, setSelectedAccount] = useState<string>("");
  const [actifAccount, seActifAccount] = useState<null | boolean>(null);
  const [selectedApplication, setSelectedApplication] = useState<string>("");
  const [drive, setDrive] = useState<string | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [driveFile, setDriveFile] = useState<string>("");

  // State
  const [getReported, setGetReported] = useState(false);
  const [templatesReport, setTemplatesReport] = useState([]);
  const [selectedReport, setSelectedReport] = useState<string[]>([]);
  const [fields, setFields] = useState<any>([]);

  // Periodicity
  const [cron, setCron] = useState<cronData | null>(null);

  //  Connector type import field
  const [url, setUrl] = useState("");
  const [categorie, setCategorie] = useState([]);
  const [sheet, setSheet] = useState("");
  const [dataMapping, setDataMapping] = useState<any>([]);
  const [apiData, setApiData] = useState<any>([]);
  const [nameCat, setNameCat] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [idFichier, setIdFichier] = useState<string>("");
  const [ignoreFiche, setIgnoreFiche] = useState<boolean>(false);
  const [itemsName, setItemsName] = useState<{ nom: string; type: string }[]>(
    []
  );
  const [filteItems, setFilteItems] = useState<FileItem[]>([]);
  const [selectedFile, setSelectedFile] = useState<CustomDataNode>();
  const [siteInDrive, setSiteInDrive] = useState<null | string>(null);
  const [idFileSelected, setIdFileSelected] = useState<string>("");
  const [choiceImport, setChoiceImport] = useState<string>("drive");
  const [root, setRoot] = useState<string>("");
  const [site, setSite] = useState("");

  // Dynamic field
  const [fieldsData, setFieldsData] = useState<ConnectorConfigInputState>({});

  const formattedAccounts: selectedItem[] = [];

  const formattedApplications: selectedItem[] = [];
  const formattedDrives: selectedItem[] = [];

  const formattedFormulaires: any[] = [];

  // const [dataDr]
  const connector = useQuery({
    queryKey: ["connector", id || ""],
    queryFn: getOneConnector,
  });

  const getReportMutation = useMutation({
    mutationFn: getReport,
    onSuccess: (response) => {
      const r = response.map((report: any) => {
        return {
          label: report.name,
          value: report.id,
          key: report.id,
        };
      });
      setTemplatesReport(r);
    },
  });

  const AllDrives = useQuery({
    queryKey: ["drivesAccount"],
    queryFn: getAllDrive,
  });

  const getNode = useQuery({
    queryKey: [
      "AnotherDriveNode",
      { id: root || "", site: site || "", drive: drive || "" },
    ],
    queryFn: getDriveNodeF,
    enabled: AllDrives.isSuccess && Boolean(drive),
  });

  const imported = connector.data?.peutImporter || false;
  const [modalDrive, setModalDrive] = useState(false);

  const genererRapport = connector.data?.genererRapport || 0;

  const Accounts = useQuery({ queryKey: ["Accounts"], queryFn: getAllAccount });

  const Applications = useQuery({
    queryKey: ["application", selectedAccount || ""],
    queryFn: getApplication,
    enabled:
      Accounts.isSuccess && Boolean(selectedAccount) && Boolean(actifAccount),
  });

  const Formulaires = useQuery({
    queryKey: [
      "formulaire",
      { idCompte: selectedAccount, short: selectedApplication },
    ],
    queryFn: getStructure,
    enabled: Applications.isSuccess && Boolean(selectedApplication),
  });

  if (AllDrives.data) {
    for (let i = 0; i < AllDrives.data.length; i++) {
      const element = AllDrives.data[i];
      formattedDrives.push({
        value: element.id,
        key: element.id,
        label: element.nom,
      });
    }
  }

  if (Accounts.data) {
    for (let i = 0; i < Accounts.data.length; i++) {
      const element = Accounts.data[i];
      formattedAccounts.push({
        value: element.id,
        key: element.id,
        label: element.nom,
      });
    }
  }

  if (Applications.data && Applications.data.length > 0) {
    for (let i = 0; i < Applications?.data.length; i++) {
      const element = Applications.data[i];
      formattedApplications.push({
        value: element.short,
        key: element.short,
        label: element.short,
      });
    }
  }

  if (
    Applications.isError &&
    Boolean(selectedAccount) &&
    Boolean(actifAccount)
  ) {
    messageApi.open({
      type: "error",
      content: "Ce compte daxium ne possède pas de VM",
      duration: 2,
    });
  }

  if (Formulaires.data) {
    for (let i = 0; i < Formulaires.data.length; i++) {
      const element = Formulaires.data[i];
      formattedFormulaires.push({
        value: element.id.toString(),
        key: element.id,
        label: element.name,
        fields: element.fields,
      });
    }
  }

  if (Formulaires.isError && Boolean(selectedApplication)) {
    messageApi.open({
      type: "error",
      content: "Cette VM daxium ne possède pas de formulaires.",
      duration: 2,
    });
  }

  // Mutation
  const createMutation = useMutation({
    mutationFn: createConfig,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["configConnector"] });
      messageApi.open({
        type: "success",
        content: "La nouvelle configuration a été ajoutée avec succès",
        duration: 2,
      });
      clearData();
      change(false);
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content:
          "une erreur est survenue lors de la création de la configuration",
        duration: 2,
      });
    },
  });

  useEffect(() => {
    if (formattedApplications.length === 1) {
      setSelectedApplication(formattedApplications[0]?.value);
    }
  }, [Applications.isSuccess]);

  useEffect(() => {
    if (formattedAccounts.length === 1) {
      setSelectedAccount(formattedAccounts[0]?.value);
    }
  }, [Accounts.isSuccess]);

  useEffect(() => {
    if (selectedFile !== null) {
      const urlFile = selectedFile?.url;

      setSiteInDrive(
        selectedFile?.siteInDrive ? selectedFile?.siteInDrive : null
      );
      setDriveFile(selectedFile?.title ? String(selectedFile.title) : "");
      if (urlFile) {
        setUrl(urlFile);
        handleSearchMutate.mutate({
          urlApi: connector.data?.callback || "",
          url: urlFile,
          type: "url",
        });
      }
    } else {
      setUrl("");
      setDriveFile("");
    }
  }, [selectedFile]);

  useEffect(() => {
    if (sheet && apiData) {
      setNameCat(apiData?.itemsLabel);
      const d = apiData?.items
        ?.find((item: any) => item.name === sheet)
        ?.data?.map((item: any, index: any) => {
          return {
            key: index,
            nom: item.name,
            type: item.type,
            formDaxium: "",
          };
        });
      const itemsName = d.map((n: any) => {
        return { nom: n.nom, type: n.type };
      });
      setDataMapping(d);
      setItemsName(itemsName);
    }
  }, [sheet, apiData]);

  useEffect(() => {
    const fields = formattedFormulaires.find(
      (form) => form.value === selectedForm
    );
    if (fields) setFields(fields.fields);
    if (selectedAccount && selectedApplication && selectedForm)
      getReportMutation.mutate({
        idCompte: selectedAccount,
        short: selectedApplication,
        idStructure: selectedForm?.toString(),
      });
  }, [selectedForm, Formulaires.isSuccess]);

  useEffect(() => {
    if (getNode.data) {
      const data: FileItem[] = [];
      for (let i = 0; i < getNode.data.length; i++) {
        const element = getNode.data[i];
        data.push(element);
        // console.log(data);
        // console.log("ici");
      }
      setFilteItems(data);
    } else {
      setFilteItems([]);
      setUrl("");
      setDriveFile("");
    }
  }, [getNode.data]);

  // Callback function

  // Creating a configuration
  const handleCreateConfig = () => {
    const values = Object.keys(fieldsData).map((id: string) => {
      return {
        idChamp: id,
        value: fieldsData[id],
      };
    });

    try {
      if (!name.trim()) throw new Error("Vous n'avez pas entré d'intitulé");

      if (!selectedAccount.trim())
        throw new Error("Vous n'avez pas sélectionné de compte daxium");

      if (!selectedApplication?.trim())
        throw new Error("Vous n'avez pas sélectionné de VM daxium");

      if (!selectedForm)
        throw new Error("Vous n'avez pas sélectionné de Formulaire daxium");

      if (imported) {
        if (dataMapping?.length === 0) {
          throw new Error("Vous n'avez fait aucun mapping");
        } else {
          for (let i = 0; i < dataMapping?.length; i++) {
            const element = dataMapping[i];

            if (element?.formDaxium.trim() === "")
              throw new Error(
                `La colonne ${element?.nom} doit être croisée à un champ daxium`
              );
          }
        }
      }
      const nbFieldsRequired =
        connector.data?.champs
          ?.filter((el: any) => el?.requis)
          .map((el) => el.id) || [];

      const isRequired = nbFieldsRequired?.map((id: string) => {
        return values.map((el) => el.idChamp).includes(id);
      });

      if (!isRequired.every((elem: boolean) => !!elem))
        throw new Error(
          `Vous devez rentrer des valeurs sur tous les champs requis`
        );
      const size: number = values?.length || 0;

      for (let i = 0; i < size; i++) {
        const element = values[i] || "";

        const champ = connector.data?.champs.find(
          (el) => el.id === element?.idChamp
        );

        if (!element?.value && champ?.requis && champ?.typeChamp.code !== "6") {
          const libelle = connector.data?.champs.find(
            (el: Champ) => el.id === element?.idChamp
          )?.libelle;

          throw new Error(
            `Aucune valeur n'est présente dans le champ : ${libelle}. `
          );
        } else if (champ?.typeChamp.code === "6") {
          const champRequisObjet: ChampRequisObjet[] =
            champ.enfants?.map((el) => {
              return { nom: el.nom, requis: el.requis, libelle: el.libelle };
            }) || [];

          const dataFieldObjet =
            JSON.parse(
              values.find(
                (el: { idChamp: string; value: string }) =>
                  el.idChamp == champ.id
              )?.value || ""
            ) || "";
          for (let index = 0; index < dataFieldObjet.length; index++) {
            const element = dataFieldObjet[index];
            for (let item = 0; item < champRequisObjet.length; item++) {
              const d = champRequisObjet[item];

              if (d.requis && element[`${d.nom}`] === "") {
                throw new Error(
                  `Le champ ${d.libelle} contenu dans ${champ.libelle} est requis. `
                );
              }
            }
          }
        }
      }
    } catch (e: any) {
      messageApi.open({
        type: "warning",
        content: e.message ? e.message : "",
        duration: 2,
      });
      return;
    }
    const mappings = dataMapping.map((item: any) => {
      return {
        nomChamp: item.nom,
        nomDaxium: item.formDaxium,
        type: item.type,
        position: item.key,
        libelleDaxium:
          fields?.find((f: any) => f.name === item?.formDaxium)?.label || "",
      };
    });
    if (id && selectedAccount && selectedApplication && selectedForm && name) {
      let idOrganisation: string = "";
      if (Accounts.data) {
        idOrganisation =
          Accounts?.data.find((item) => item.id === selectedAccount)
            ?.idOrganisation || "";
      }
      const dataInsert = {
        idConnecteur: id,
        idOrganisation: idOrganisation,
        idCompte: selectedAccount.toString(),
        nom: name,
        short: selectedApplication.toString(),
        idElement: selectedForm,
        actif: true,
        getReport: getReported,
        templates: JSON.stringify(selectedReport),
        values: values,
        item: sheet,
        idFichier: idFichier,
        mappings: mappings,
        url: url,
        idObjetDaxium: selectedForm.toString(),
        nomObjetDaxium: selectedApplication.toString(),
        typeObjetDaxium: selectedForm.toString(),
        cron: cron,
        ignoreFiche,
        idDrive: drive || "",
        fileInDriveId: selectedFile?.key.toString() || "",
        siteInDrive,
        fileInDrive: Boolean(selectedFile),
      };
      createMutation.mutate(dataInsert);
    }
  };

  const handleChangeForm = (e: string) => {
    setSelectedForm(e);
  };

  const handleBeforUploadMutate = useMutation({
    mutationFn: getConfigMappingFile,
    onSuccess: (data) => {
      messageApi.open({
        type: "success",
        content: "Le mapping a été réalisé avec success.",
        duration: 2,
      });
      const cate = data?.items?.map((cat: any) => {
        return { label: cat.name, value: cat.name };
      });
      setApiData(data);

      setCategorie(cate);
      setSheet(cate[0]?.value);
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue lors du mapping.",
        duration: 2,
      });
      setCategorie([]);
      setSheet("");
      setDataMapping([]);
      setApiData([]);
    },
  });

  const handleBeforeUploadFile = (e: any) => {
    let retour = false;

    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = () => {
      const d = reader.result;
      if (d) {
        const base64 = d.toString().split(",").slice(1).toString();
        handleBeforUploadMutate.mutate({
          urlApi: connector.data?.callback || "",
          data: base64,
          type: "file",
        });
        retour = false;
      }
    };
    reader.onerror = (error) => {
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue lors du televersement du fichier",
        duration: 2,
      });
      retour = true;
    };
    return retour;
  };

  const removeFileMutate = useMutation({
    mutationFn: deletedFile,
    onSuccess: () => {
      messageApi.open({
        type: "info",
        content: "Le Fichier a été supprimé",
        duration: 2,
      });
      setIdFichier("");
    },
    onError: () => {
      messageApi.open({
        type: "error",
        content: "Le Fichier n'a pas été supprimé",
        duration: 2,
      });
    },
  });

  const handleRemoveFile = () => {
    setDataMapping([]);
    setSheet("");
    setCategorie([]);

    setFileList([]);
    setNameCat("");
    if (idFichier) removeFileMutate.mutate(idFichier);
  };

  const handleSearchMutate = useMutation({
    mutationFn: getConfigMappingUrl,
    onSuccess: (data) => {
      messageApi.open({
        type: "success",
        content: "Le mapping a été réalisé avec success.",
        duration: 2,
      });
      const cate = data?.items?.map((cat: any) => {
        return { label: cat.name, value: cat.name };
      });
      setApiData(data);
      setCategorie(cate);
      setSheet(cate[0]?.value);
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue lors du mapping.",
        duration: 2,
      });
      setCategorie([]);
      setSheet("");
      setDataMapping([]);
      setApiData([]);
    },
  });

  const handleSearch = () => {
    if (url.trim() !== "") {
      handleSearchMutate.mutate({
        urlApi: connector.data?.callback || "",
        url: url,
        type: "url",
      });
    } else {
      messageApi.open({
        type: "warning",
        content: "Veillez entrez une url valide",
        duration: 2,
      });
    }
  };

  const handleSwitchReport = (report: boolean) => {
    if (selectedForm) {
      if (templatesReport.length === 0) {
        messageApi.open({
          type: "warning",
          content: "Ce formulaire ne possède pas de template rapport",
          duration: 2,
        });
        return;
      }

      setGetReported(report);
    } else {
      messageApi.open({
        type: "warning",
        content: "Aucun formulaire n'a été sélectionné",
        duration: 2,
      });
    }
  };

  // Modal management
  const handleOk = () => {
    change(false);
    clearData();
  };

  const handleCancel = () => {
    showDeleteConfirm();
  };

  // Cleaning the form
  const clearData = () => {
    setFieldsData({});
    setName("");
    // setForms([]);

    setSelectedAccount("");
    setSelectedApplication("");
    setSelectedForm("");

    setDataMapping([]);
    setCategorie([]);
    setSheet("");
    setNameCat("");
    setSelectedReport([]);
    setFileList([]);

    setDrive("");
    // booleeen
    // setShowSelectedApplication(false);
    // setShowSelectedForm(false);
    setGetReported(false);
    // setLoadingApplication(false);
    // setLoadingForm(false);
  };

  const handleUpload = (content: any) => {
    const myHeaders: Headers = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    if (content && content.length > 0) {
      const file: File = content[0].originFileObj;
      const formdata: FormData = new FormData();
      formdata.append("file", file, file.name);

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(HOST + "/fichier/upload", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const id = JSON.parse(result).id;
          setIdFichier(id);
        })
        .catch((error) => console.error("error", error));
    } else {
      // console.error("No file selected");
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title:
        "Voulez-vous supprimer cette configuration, toutes les données présentes sur le formulaire seront perdues.",
      icon: <ExclamationCircleOutlined />,
      okText: "Continuer",
      okType: "danger",
      cancelText: "Annuler",
      centered: true,
      width: "600px",
      onOk() {
        change(false);
        clearData();
      },
      onCancel() {},
    });
  };
  const onChangeAccount = (val: string) => {
    const account = Accounts?.data
      ? Accounts?.data.find((ac) => ac.id === val)
      : null;
    setSelectedAccount(val);
    if (account && !account.actif) {
      message.warning(
        "Ce compte est inactif, veuillez vous reconnectez à ce compte Daxium."
      );

      seActifAccount(account.actif);
      setSelectedApplication("");
      setSelectedForm("");
    } else {
      seActifAccount(true);
      queryClient.invalidateQueries({ queryKey: ["formulaire"] });
    }
  };

  return (
    <>
      {modalDrive && (
        <ModalFileDrive
          visible={modalDrive}
          data={filteItems}
          updateData={setFilteItems}
          change={setModalDrive}
          drive={drive || ""}
          updateFile={setSelectedFile}
          fileSelected={selectedFile || null}
          idFileSelected={idFileSelected}
        />
      )}
      <Modal
        style={{
          top: 10,
          minWidth: 900,
        }}
        title=""
        width={900}
        centered={true}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              key="back"
              size={"large"}
              onClick={handleCancel}
              style={{
                fontSize: "1rem",
                padding: "5px",
                textAlign: "center",
                width: "120px",
              }}
            >
              ANNULER
            </Button>
            <Button
              key="submit"
              size={"large"}
              onClick={handleCreateConfig}
              style={{
                background: !Boolean(actifAccount) ? "" : "#4e8ccb",
                color: !Boolean(actifAccount) ? "black" : "white",
                fontSize: "1rem",
                paddingLeft: "13px",
                paddingRight: "13px",
                textAlign: "center",
                width: "auto",
              }}
              loading={createMutation.isPending}
              disabled={!Boolean(actifAccount)}
            >
              AJOUTER LE FORMULAIRE
            </Button>
          </div>,
        ]}
      >
        {contextHolder}
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Content>Ajout d'une configuration</Content>
          <Container imported={imported}>
            <ContainerInput direction="horizontal">
              <Title>
                Intitulé <span style={{ color: "red" }}>*</span> :{" "}
              </Title>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Entrez un intitulé"
                type="text"
                required={true}
                style={{ width: 400, height: 40, margin: 0 }}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title>
                VM daxium <span style={{ color: "red" }}>*</span> :
              </Title>
              <Select
                showSearch={true}
                optionFilterProp="label"
                disabled={!Applications.isSuccess}
                style={{ width: 400, margin: 0, padding: 0 }}
                size="large"
                loading={Applications.isLoading}
                value={selectedApplication}
                placeholder="Choississez une VM"
                onChange={(val: string) => {
                  setSelectedApplication(val);
                  queryClient.invalidateQueries({ queryKey: ["application"] });
                }}
                options={formattedApplications}
              />
            </ContainerInput>
            <>
              <ContainerInput direction="horizontal">
                <Title>
                  Compte daxium <span style={{ color: "red" }}>*</span> :
                </Title>
                <Select
                  showSearch={true}
                  optionFilterProp="label"
                  placeholder="Choisissez un compte"
                  style={{ width: 400, margin: 0, padding: 0 }}
                  size="large"
                  value={selectedAccount}
                  onChange={onChangeAccount}
                  options={formattedAccounts}
                  loading={Accounts.isLoading}
                />
                {actifAccount === false && (
                  <p style={{ color: "red" }}>Ce compte est inactif</p>
                )}
              </ContainerInput>
            </>
            <ContainerInput direction="horizontal">
              <Title>
                Formulaires <span style={{ color: "red" }}>*</span> :
              </Title>
              <Select
                showSearch={true}
                optionFilterProp="label"
                placeholder={"Choississez un formulaire"}
                disabled={!Formulaires.isSuccess}
                style={{ width: 400 }}
                size="large"
                loading={Formulaires.isLoading}
                value={selectedForm}
                onChange={(val: string) => {
                  handleChangeForm(val);
                }}
                options={formattedFormulaires}
              />
            </ContainerInput>
            {[1, 3].includes(genererRapport) && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    height: 70,
                    paddingBottom: 15,
                  }}
                >
                  <Title>Générer rapport</Title>
                  <Switch
                    onChange={handleSwitchReport}
                    checked={getReported}
                    loading={getReportMutation.isPending}
                  />
                </div>
                <ContainerInput direction="horizontal">
                  <Title> Template rapport :</Title>
                  <Select
                    mode="multiple"
                    showSearch={true}
                    optionFilterProp="label"
                    placeholder="Choississez le template"
                    disabled={!getReported}
                    loading={getReportMutation.isPending}
                    style={{ width: 400 }}
                    size="large"
                    value={selectedReport}
                    onChange={setSelectedReport}
                    options={templatesReport}
                  />
                </ContainerInput>
              </>
            )}
          </Container>
          {/* Si le connecteur peut importer des fichiers */}
          {imported && (
            <>
              <Divider />

              <ConfigProvider
                theme={{
                  components: {
                    Radio: {
                      colorPrimary: "#4e8ccb",
                    },
                  },
                }}
              >
                <Radio.Group
                  value={choiceImport}
                  size="large"
                  buttonStyle="solid"
                  onChange={(e) => {
                    const value = e.target.value;

                    setChoiceImport(value);
                  }}
                  style={{ width: 840, textAlign: "center" }}
                >
                  <Radio.Button value="drive" style={{ width: "33%" }}>
                    Importer le fichier depuis un drive
                  </Radio.Button>
                  <Radio.Button value="url" style={{ width: "34%" }}>
                    Importer le fichier via une URL
                  </Radio.Button>
                  <Radio.Button value="file" style={{ width: "33%" }}>
                    Téléverser le fichier
                  </Radio.Button>
                </Radio.Group>
              </ConfigProvider>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill,400px)",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                {choiceImport === "drive" && (
                  <>
                    <ContainerInput direction="horizontal">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: 400,
                          alignItems: "center",
                        }}
                      >
                        <Select
                          showSearch={true}
                          optionFilterProp="label"
                          placeholder="Sélectionnez le drive"
                          style={{ width: 250 }}
                          size="large"
                          value={drive}
                          onChange={(value: string) => {
                            const infoDrive = AllDrives.data
                              ? AllDrives.data.find((item) => item.id === value)
                              : null;
                            if (infoDrive) {
                              setRoot(
                                infoDrive.root === "onedrive"
                                  ? ""
                                  : infoDrive.root
                              );
                              setSite(infoDrive.site);
                            }
                            setDrive(value);
                            queryClient.invalidateQueries({
                              queryKey: ["AnotherDriveNode"],
                            });
                            queryClient.invalidateQueries({
                              queryKey: ["DriveNodeFile"],
                            });
                          }}
                          loading={AllDrives.isLoading}
                          disabled={AllDrives.isLoading}
                          options={formattedDrives}
                        />
                        <Button
                          key="submit"
                          size={"large"}
                          onClick={() => {
                            if (!drive) {
                              messageApi.open({
                                type: "warning",
                                content: "Aucun drive n'a été sélectionné.",
                                duration: 2,
                              });
                            } else if (filteItems.length === 0) {
                              messageApi.open({
                                type: "warning",
                                content: "Le dossier parent est vide.",
                                duration: 2,
                              });
                            } else {
                              setModalDrive(!modalDrive);
                            }
                          }}
                          style={{
                            background: "#4e8ccb ",
                            color: "white",
                            fontSize: "1rem",
                            textAlign: "center",
                            marginLeft: 20,
                          }}
                          loading={getNode.isLoading}
                        >
                          recupérer le fichier
                        </Button>
                      </div>
                    </ContainerInput>
                    <ContainerInput direction="horizontal">
                      <Input
                        placeholder="Sélectionner le fichier sur le drive"
                        type="text"
                        value={driveFile}
                        disabled
                        style={{ width: 400, height: 40, margin: 0 }}
                      />
                    </ContainerInput>
                  </>
                )}
                {choiceImport === "file" && (
                  <ContainerInput direction="horizontal">
                    <Dragger
                      action=""
                      id="fileInput"
                      beforeUpload={handleBeforeUploadFile}
                      style={{ width: 840, height: 80 }}
                      maxCount={1}
                      onRemove={handleRemoveFile}
                      disabled={url !== ""}
                      fileList={fileList}
                      onChange={({ fileList: newFileList }) => {
                        setFileList(newFileList);
                        if (!idFichier) handleUpload(newFileList);
                      }}

                      // accept={".xlsx"}
                    >
                      <p className="ant-upload-drag-icon">{""}</p>
                      <p className="ant-upload-text">
                        Cliquer ou déposer le fichier ici
                      </p>
                      <p className="ant-upload-drag-icon">{""}</p>
                    </Dragger>
                  </ContainerInput>
                )}
                {choiceImport === "url" && (
                  <>
                    <ContainerInput direction="horizontal">
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              colorPrimary: "#4e8ccb",
                            },
                          },
                        }}
                      >
                        <Search
                          enterButton="Mapper"
                          size="large"
                          value={url}
                          onChange={(val) => {
                            setUrl(val.target.value);
                          }}
                          onSearch={handleSearch}
                          style={{ width: 840 }}
                          loading={handleSearchMutate.isPending}
                        />
                      </ConfigProvider>
                    </ContainerInput>
                  </>
                )}
              </div>
              <Divider />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill,400px)",
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <Periodicite
                  data={cron}
                  change={setCron}
                  update={true}
                  disabled={choiceImport === "file"}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Checkbox
                    checked={ignoreFiche}
                    onChange={(e: CheckboxChangeEvent) =>
                      setIgnoreFiche(e.target.checked)
                    }
                  >
                    <p
                      style={{
                        fontSize: "1.02rem",
                        marginBottom: 0,
                        paddingTop: 0,
                        alignItems: "left",
                      }}
                    >
                      Ignore fiche si un champ est vide
                    </p>
                  </Checkbox>
                </div>
              </div>
            </>
          )}
          {/* Tableau dynamique connecteur de type import */}

          {imported && (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Title>
                  MAPPING DES CHAMPS <span style={{ color: "red" }}>*</span>{" "}
                </Title>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.02rem",
                      marginBottom: 0,
                      paddingTop: 0,
                      alignItems: "left",
                    }}
                  >
                    {nameCat} {nameCat && " :"}
                  </p>
                  <Select
                    showSearch={true}
                    optionFilterProp="label"
                    placeholder="Sélectionnez une fiche"
                    disabled={categorie?.length === 0}
                    style={{ width: 250, margin: 0, padding: 0 }}
                    size="large"
                    onChange={setSheet}
                    value={sheet}
                    options={categorie}
                    loading={
                      handleSearchMutate.isPending ||
                      handleBeforUploadMutate.isPending
                    }
                  />
                </div>
              </div>
              <TableEditable
                dataSource={dataMapping}
                setDataSource={setDataMapping}
                fieldsDaxium={fields}
                loading={
                  handleSearchMutate.isPending ||
                  handleBeforUploadMutate.isPending
                }
                itemsName={itemsName}
              />
            </div>
          )}

          <Divider />
          {/* Champ dynamique */}

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill,400px)",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {connector.data &&
              orderBy(connector.data.champs, ["position", "asc"])
                .filter((c) => +c.typeChamp.code !== 6)
                .map((field) => {
                  return (
                    <InputField
                      id={field.id}
                      key={field.id}
                      isConcat={field.isConcat}
                      required={field?.requis}
                      aide={field.aide}
                      libelle={field.libelle}
                      placeholder={field.placeholder}
                      isCallback={field.isCallback}
                      value={fieldsData}
                      setValue={setFieldsData}
                      type={field?.typeChamp?.code || ""}
                      fields={fields || []}
                      enfants={field?.enfants || []}
                      options={field?.options ? JSON.parse(field?.options) : []}
                    />
                  );
                })}
          </div>
          {connector.data &&
            connector.data.champs.filter((c) => +c.typeChamp.code !== 6)
              .length > 1 && <Divider />}
          <div>
            {connector &&
              orderBy(connector.data?.champs, ["position", "asc"])
                .filter((c, index) => +c.typeChamp.code === 6)
                .map((field) => {
                  // console.log(field);
                  return (
                    <InputObjet
                      id={field.id}
                      key={field.id}
                      required={field?.requis}
                      libelle={field.libelle}
                      placeholder={field.placeholder}
                      value={fieldsData}
                      aide={field.aide}
                      setValue={setFieldsData}
                      type={field?.typeChamp?.code || ""}
                      fields={fields || []}
                      enfants={
                        orderBy(field.enfants, ["position", "asc"]) || []
                      }
                      // options={field?.options ? JSON.parse(field?.options) : []}
                    />
                  );
                })}
          </div>
        </div>
      </Modal>
    </>
  );
};

interface data {
  visible: boolean;
  change: any;
}

const Container = styled.div<{ imported: boolean }>`
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  width: 100%;
  justify-content: space-between;
`;

const ContainerInput = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const Title = styled.p`
  font-size: 1.02rem;
  margin-bottom: 0;
  margin-bottom: 0;
  padding-top: 0;
  font-weight: 650;
  align-items: left;
`;
const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 3px;
  width: 100%;
`;
interface ChampRequisObjet {
  nom: string;
  requis: boolean;
  libelle: string;
}
interface Field {
  id: string;
  enfants?: any[];
  idConnecteur: string;
  idTypeChamp: string;
  nom: string;
  libelle: string;
  position: number;
  requis: boolean;
  createdDate: string;
  updatedDate: string;
  typeChamp: any;
  aide?: string;
  options?: string;
}

interface cronData {
  heure: number;
  minute: number;
  coef: number;
  ignoreFiche: boolean;
  frequence: number;
  jour: number;
  dernierJour: boolean;
  mois: number;
  dim: boolean;
  lun: boolean;
  mar: boolean;
  mer: boolean;
  jeu: boolean;
  ven: boolean;
  sam: boolean;
}
interface selectedItem {
  label: string;
  value: string;
  key: string;
}
export default ModalAddConfiguration;
