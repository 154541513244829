import React, { ChangeEventHandler } from "react";
import { Col, Row } from "../../../components/styled/Styled.Container";
import { Label } from "../../../components/styled/Styled.Typography";
import { TextField } from "../../../components/styled/Styled.Form";
import { InputElementProps } from "../../../models/components-props.model";

const TypeEmail: React.FC<InputElementProps> = ({
  value,
  setValue,
  onClick,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };
  return (
    <Col>
      <Row alignStart width={"90%"}>
        <Label htmlFor="field-mail">Email</Label>
      </Row>

      <Row alignStart width={"90%"}>
        <TextField
          id="field-mail"
          autoFocus
          line
          value={value}
          onChange={handleChange}
          onKeyDown={(e: any) => {
            if (e.code === "Enter") {
              onClick();
            }
          }}
        />
      </Row>
    </Col>
  );
};

export default TypeEmail;
