import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import { createBrowserRouter } from "react-router-dom";
import routes from "./dashboard.routes";
import ForgetPassword from "../pages/ForgetPassword";
import ProtectedRoute from "../components/ProtectedRoute";
import InvitedLink from "../pages/InvitedLink";
import ExpirateLink from "../pages/ExpirateLink";
import AcceptInvitation from "../pages/AcceptInvitation";

const router = createBrowserRouter([
  // Login account route
  {
    path: "/auth",
    element: <Login />,
  },
  // Forget password : account route
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/invitation",
    element: <InvitedLink />,
  },
  {
    path: "/expirate",
    element: <ExpirateLink />,
  },
  {
    path: "/accept-invitation",
    element: <AcceptInvitation />,
  },
  // Dashboard  route
  {
    path: "/",
    element: <ProtectedRoute element={<Dashboard />} />,
    children: routes,
  },
]);

export default router;
