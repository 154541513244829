import React from "react";
import { Table } from "antd";

interface Table {
  columns?: any;
  dataSource?: any[];
  pageSize?: number;
  loading?: boolean;
}

const TableCustom: React.FC<Table> = ({
  columns,
  dataSource,
  pageSize,
  loading,
}) => {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      locale={{ emptyText: "Aucune donnée à Afficher" }}
      size="small"
      pagination={{ pageSize: pageSize }}
    />
  );
};
export default TableCustom;
