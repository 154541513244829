import React, { ChangeEventHandler } from "react";
import { Center, Col, Row } from "../../../components/styled/Styled.Container";
import { Label } from "../../../components/styled/Styled.Typography";
import {
  ButtonOutlined,
  TextField,
} from "../../../components/styled/Styled.Form";
import { BsArrowLeft } from "react-icons/bs";
import { TypePasswordProps } from "../../../models/components-props.model";

const TypePassword: React.FC<TypePasswordProps> = ({
  value,
  setValue,
  email,
  prevStep,
  onClick,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };
  return (
    <Col>
      <Center>
        <p>
          <ButtonOutlined width="100%" primary onClick={() => prevStep()}>
            <BsArrowLeft style={{ marginBottom: "-3px" }} />
            {email}
          </ButtonOutlined>
        </p>
      </Center>
      <Row alignStart width={"90%"}>
        <Label htmlFor="field-pw">Mot de passe</Label>
      </Row>

      <Row alignStart width={"90%"}>
        <TextField
          id="field-pw"
          autoFocus
          line
          value={value}
          onChange={handleChange}
          type="password"
          onKeyDown={(e: any) => {
            if (e.code === "Enter") {
              onClick();
            }
          }}
        />
      </Row>
    </Col>
  );
};

export default TypePassword;
