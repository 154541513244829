import React, { useState } from "react";
import { Modal, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalModifiedConfiguration from "../../components/ModalModifiedConfiguration";
import { BiSolidCopyAlt } from "react-icons/bi";
import { Checkbox } from "antd";
import { useParams } from "react-router-dom";
import { HOST } from "../../api";
import TableCustom from "../../components/TableCustom";
import { VscDebugStart } from "react-icons/vsc";
import { orderBy } from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getOneConnector } from "../../api/connector";
import {
  changeStatus,
  deleteConfig,
  getConfigByConnecteur,
  start,
} from "../../api/connector-config";

const TableConnector: React.FC = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const connector = useQuery({
    queryKey: ["connector", id || ""],
    queryFn: getOneConnector,
  });

  const configs = useQuery({
    queryKey: ["configConnector", id || ""],
    queryFn: getConfigByConnecteur,
  });

  const deleteMutate = useMutation({
    mutationFn: deleteConfig,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["configConnector"] });
      messageApi.open({
        type: "success",
        content: `la configuration a été supprimer avec succès.`,
        duration: 2,
      });
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: `Une erreur est survenue lors de la suppréssion de la configuration.`,
        duration: 2,
      });
    },
  });

  const startMutate = useMutation({
    mutationFn: start,
    onSuccess: () => {
      messageApi.open({
        type: "success",
        content: `l'import des données a été déclenché`,
        duration: 2,
      });
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: `l'import des données n'a pas pu  être déclenché`,
        duration: 2,
      });
    },
  });

  const changeStatusMutate = useMutation({
    mutationFn: changeStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["configConnector"] });
      messageApi.open({
        type: "success",
        content: data.actif
          ? `la configuration ${data.nom} a été activé. `
          : `la configuration ${data.nom} a été désactivé. `,
        duration: 2,
      });
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: `Une erreur est survenue lors de la mise à jour la configuration.`,
        duration: 2,
      });
    },
  });

  const [modified, SetModified] = useState(false);
  const [data, SetData] = useState({});
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  let imported = false;

  const dynamicFields = [];

  if (connector.data) {
    for (let index = 0; index < connector.data.champs.length; index++) {
      const element = connector.data.champs[index];
      dynamicFields.push({
        title: element.libelle,
        dataIndex: element.id,
        key: element.id,
        ellipsis: true,
      });
    }
    imported = connector.data.peutImporter;
  }
  dynamicFields.splice(3);

  const dynamicData = orderBy(configs.data, ["createdDate"], ["desc"]).map(
    (config) => {
      const fieldsData = config.configConnecteurChamps.map(
        ({ idChamp, value }) => {
          return {
            [idChamp]: value,
          };
        }
      );
      const data = Object.keys({ ...fieldsData }).reduce((acc, curr: any) => {
        const innerObj = fieldsData[curr];
        const innerObjKey = Object.keys(innerObj)[0];
        const innerObjValue = innerObj[innerObjKey];
        return { ...acc, [innerObjKey]: innerObjValue };
      }, {});
      return {
        key: config.id,
        id: config.id,
        nom: config.nom,
        actif: config.actif,
        ...data,
      };
    }
  );

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Voulez vous vraiment supprimer la configuration " + record?.nom,
      icon: <ExclamationCircleOutlined />,
      okText: "Supprimer",
      okType: "danger",
      cancelText: "Annuler",
      centered: true,
      width: "600px",
      onOk() {
        deleteMutate.mutate(record.id);
      },
      onCancel() {},
    });
  };

  const handleCopy = (id: string) => {
    const val = `${HOST}/config-connecteur/callback-fiche/${id}`;
    navigator.clipboard.writeText(val);
    messageApi.open({
      type: "success",
      content: `l'url ${val} a été bien copié`,
      duration: 2,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Intitulé",
      dataIndex: "nom",
      key: "nom",
      ellipsis: true,
    },
    ...dynamicFields,
    {
      title: "Actif",
      dataIndex: "actif",
      align: "center",
      render: (value, record) => {
        return (
          <div>
            <Checkbox
              checked={value}
              onClick={() => {
                changeStatusMutate.mutate({
                  idConfigConecteur: record.id,
                  actif: !record.actif,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      align: "center",
      render: (id, record) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {imported && (
              <VscDebugStart
                style={{ cursor: "pointer" }}
                onClick={() => {
                  startMutate.mutate(record.id);
                }}
              />
            )}
            <BsFillPencilFill
              onClick={() => {
                SetData(record);
                SetModified(true);
              }}
              style={{ cursor: "pointer" }}
            />
            <BiSolidCopyAlt
              onClick={() => handleCopy(id)}
              style={{ cursor: "pointer" }}
            />
            <BsFillTrashFill
              style={{ cursor: "pointer" }}
              onClick={() => showDeleteConfirm(record)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {contextHolder}
      {modified && (
        <ModalModifiedConfiguration
          visible={modified}
          change={SetModified}
          data={data}
        />
      )}
      <TableCustom
        columns={columns}
        dataSource={dynamicData}
        pageSize={2}
        loading={
          configs.isLoading ||
          changeStatusMutate.isPending ||
          deleteMutate.isPending
        }
      />
    </div>
  );
};

export default TableConnector;
