import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardForm,
  Center,
  CenterScreen,
  Col,
} from "../../components/styled/Styled.Container";
import { Title2, Title4 } from "../../components/styled/Styled.Typography";
import { Logo } from "../../components/styled/Styled.Asset";
import logo from "../../assets/daxium-logo.png";
import { Button, message } from "antd";
import { TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { acceptInvitation } from "../../api/organisation";

export default function InvitedLink() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const register = useMutation({
    mutationFn: acceptInvitation,
    onSuccess: (data) => {
      messageApi.open({
        type: "success",
        content: "Bienvnue sur Daxium-Connect",
        duration: 2,
      });
      navigate("/");
    },
    onError: (err) => {
      messageApi.open({
        type: "warning",
        content: "Une erreur c'est produite lors de l'ajout du compte ",
        duration: 2,
      });
    },
  });
  const [token, setToken] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("noreply") || "");
    if (!searchParams.get("noreply")) {
      navigate("/");
    }
  }, []);

  const submit = () => {
    try {
      if (!name.trim()) throw new Error("Vous n'avez pas saisi votre nom");

      if (!lastName.trim())
        throw new Error("Vous n'avez pas saisi votre prenom");

      if (!password?.trim().length)
        throw new Error("Le mot de passe doit avoir minimum 8 caractères");

      if (!password?.trim())
        throw new Error("Vous n'avez pas saisi de mot de passe");
    } catch (e) {
      messageApi.open({
        type: "warning",
        content: e.message ? e.message : "",
        duration: 2,
      });
      return;
    }
    if (name && lastName && password) {
      register.mutate({
        token: token,
        mdp: password,
        nom: name,
        prenom: lastName,
      });
    }
  };

  return (
    <CenterScreen>
      {contextHolder}
      <Col>
        {" "}
        {/* Le Logo de la plateforme  */}
        <Logo srcSet="" src={logo} alt="logo-daxium" />
        <CardForm
          loading={register.isPending}
          style={{
            minWidth: 410,
            maxWidth: 410,
            minHeigth: 300,
          }}
        >
          <Center column>
            <Col>
              {" "}
              {/* Le titre du formulaire */}
              <Title2>
                Bienvenue sur
                <Title2 capitalize primary ml>
                  Daxium-connect
                </Title2>
              </Title2>
              <Title4>Renseignez vos informations de connexion</Title4>
            </Col>

            {/* LES ETAPES DE CONNEXION AU COMPTE */}
            <TextField
              label={"Nom"}
              type={"text"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              size="small"
              style={{
                fontWeight: "400",
                width: "85%",
              }}
              variant="standard"
            />
            <TextField
              label={"Prenom"}
              type={"text"}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              size="small"
              style={{
                fontWeight: "400",
                width: "85%",
              }}
              variant="standard"
            />
            <TextField
              label={"Mot de passe"}
              type={"password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              size="small"
              style={{
                fontWeight: "400",
                width: "85%",
                marginBottom: 18,
              }}
              variant="standard"
            />
            <Button
              style={{
                width: "85%",
                backgroundColor: "#4E8CCB",
                color: "white",
                textTransform: "uppercase",
                fontWeight: "700",
                fontFamily: "Source Sans Pro",
              }}
              onClick={submit}
              loading={register.isPending}
            >
              S'enregistrer
            </Button>
          </Center>
        </CardForm>
      </Col>
    </CenterScreen>
  );
}
