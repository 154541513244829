import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ConnectorProps } from "../../models/components-props.model";
import { useNavigate } from "react-router-dom";

const Connector: React.FC<ConnectorProps> = ({ imageUrl, label, id }) => {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate(`config-connector/${id}`)}>
      <ContainerImage>
        <Img src={imageUrl} alt={label} />
      </ContainerImage>
      <Title>{label}</Title>
    </Container>
  );
};

// Style
const Container = styled.div`
  width: 180px;
  height: 130px;
  margin: 4px;
  border-radius: 5px;
  box-shadow: 10px 24px 54px 0px rgba(0, 0, 0, 0.03);
  transition: all 0.9s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  justify-content: center;
  align-items: center;

  background: radial-gradient(
    circle,
    rgba(76, 147, 148, 0.7),
    rgb(76, 147, 148)
  );
  &:hover {
    transform: scale(1.05); /* Augmente la taille de l'élément */
  }
`;
const Title = styled.span`
  color: white;
  flex: 1;
`;
const Img = styled.img`
  width: 50px;
  height: 50px;
  margin-top: 50%;
`;
const ContainerImage = styled.div`
  flex: 4;
  align-items: center;
`;
export default Connector;
